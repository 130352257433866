import React from "react";
import { PieChart, Pie, Cell, Legend } from "recharts";

function PieChartComponent({ data, width, col, type }) {
  const COLORS = [  "#00B21E", "#1A91FF","#A40E17",];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill='#FFFFFF'
        style={{ fontSize: "10px", fontWeight: 700 }}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline='central'>
        {percent !== 0 && `${(percent * 100).toFixed(0)}%`}
      </text>
    );
  }; 
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        flexDirection: "row",
       
      }}>
      <div
        style={{ flex: "2", minHeight: "300px" }}
        className=' flex items-center justify-center flex-col'>
        <PieChart width={width ? width : 400} height={300}>
          <Pie
            data={data}
            // cx={120}
            cy={150}
            labelLine={false}
            label={renderCustomizedLabel}
            innerRadius={0}
            outerRadius={120}
            fill='#8884d8'
            dataKey='value'
            stroke='none'>
            {data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </div>
    </div>
  );
}

export default PieChartComponent;
