import React, { useState, useEffect } from "react";
import { Modal, Input, Switch, Select, Tooltip, Checkbox } from "antd";
import floppyDiskImg from "../../../../assets/img/floppy-disk.svg";
import puzzle from "../../../../assets/img/bl.svg";
import { ReceptionRadioButtons } from "../buttons/reception-button";
import { Link, useNavigate } from "react-router-dom";
import Title from './components/title.js'

function TargetModal({
  state,
  dispatch,
  close,
  update,
  item,
  school_id,
  loadAllPlayerList,
  schoolCustomStats,
  getStats,
  play,
  handleUpdatePlay,
  updateInputGrade,
  updateGrade,
  
}) {
  const [modalWidth, setModalWidth] = useState(425);
  let history = useNavigate();
  const [customStats, setCustomStats] = useState([]);
  const [customStatValues, setCustomStatValues] = useState(JSON.parse(item.custom_stats || '[]'));
const [ storage, setStorage ] = useState({})

const handleSaveCustomStat = (stat, value) => {

  const _customStatValues = {...customStatValues}
  _customStatValues[stat.label] =  value;
  setCustomStatValues(_customStatValues)
 
updateGrade('custom_stats', JSON.stringify(_customStatValues))

}

const handleCStats = () => {
  setCustomStatValues(JSON.parse(item.custom_stats || '{}')) 
  const _customStats = schoolCustomStats.filter(
    (stat) => stat.odk == "o"
  );
  setCustomStats(_customStats?.length ? _customStats?.filter((stat)=> stat.index == '2') : []);
}
useEffect(handleCStats, [item.custom_stats, schoolCustomStats]); 
useEffect(handleCStats, []); 

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setModalWidth(352);
    } else {
      setModalWidth(425);
    }
  }, [window.innerWidth]);

  return state.ol && (
    <Modal
      open={state.ol}
      onOk={close}
      onCancel={close}
      closable={true}
      footer={null}
      width={modalWidth}
    
      title={
        <div className="flex font-inter text-[14px] text-[#0F1A24] items-center">
          <span className=" font-bold">Offensive Line</span>
          <div className="w-[1px] h-[30px] bg-[#A9B6C2] mx-2"></div>
          <span className="font-[400]">
            {item?.first_name} {item?.last_name}
          </span>
        </div>
      }
    >
      <div
        style={{ border: "1px solid #F3F5F7" }}
        className="mt-4 text-[12px] text-[#0F1A24]"
      >
        
      <div className="mt-4 px-2">
            <span className=" font-inter text-[14px] font-bold">
              Custom  Stats{" "}
            </span>
            <div
              style={{ border: "1px solid #F3F5F7" }}
              className="mt-4 text-[12px] text-[#0F1A24] mb-2"
            >

              {
                  customStats?.map((stat, index) => {

                    return(
              <div
                className="flex items-center"
                style={{ borderBottom: "1px solid #F3F5F7" }}
              >
                <div
                  className="w-[70%] p-3 font-bold"
                  style={{ borderRight: "1px solid #F3F5F7" }}
                >
                  <span>{stat.label}</span>
                </div>{" "}
                <div className="p-3 w-[30%] turn-over actor flex items-center justify-center"> 
                 { stat.input_type == "numeric" ?
                 
   (<Input defaultValue={JSON.parse(item.custom_stats || '{}')[stat.label]} onChange={(e)=>handleSaveCustomStat(stat,e.target.value)} size="small" className="w-[40%] p-1" />)
   :
   (<Switch
    defaultChecked={JSON.parse(item.custom_stats || '{}')[stat.label]}
    onChange={(e) => handleSaveCustomStat(stat, e ? 1 : 0)}
  />)}
        
                </div>
              </div>
                    )

                  })
              }

             
            </div>
            <span className="text-[#8698A9]">
              Edit custom stats in your{" "}
              <span onClick={() => history("/add-stats")} className="underline">Admin Settings</span>
            </span>
          </div>
        <div className="flex items-center justify-between mt-2">
          <button onClick={close} className="flex w-[49%] items-center focus:outline-0 justify-center border-[1px] border-[#1A91FF] text-[#1A91FF] h-11 rounded-[4px]">
            Cancel
          </button>
          <button onClick={close} className="flex w-[49%] items-center focus:outline-0 justify-center bg-[#1A91FF] text-[#ffffff] h-11 rounded-[4px]">
            <img src={floppyDiskImg} className="w-6" />
            <span className="ml-2">Save and close</span>
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default TargetModal;
