import React, { useEffect, useState } from "react";

import _ from "lodash";

import { Menu } from "antd";

import {
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import routeConfigs from "./private-route-config";
import coachRouteConfigs from "./coach-private-route-config";
import adminRouteConfigs from "./admin-route-config";
import publicRouteConfigs from "./public-route-config";
import global from "../config/global";
import { connect } from "react-redux";
import appPages from "./app-pages";

import RedirectRouteConfig from "../routes/redirect-route-config";
import logs from "../utils";
import { Spin } from "antd";
import PageHeader from "../../components/Header";
import PageFooter from "../../components/Footer";
import PageSidebar from "../../components/Sidebar";

const RoutesPage = (props) => {
  let navigate = useNavigate();

  // Declare a new state variable, which we'll call "appRouteConfigs"
  const [appRouteConfigs, setAppRouteConfigs] = useState([]);

  // Declare a new state variable, which we'll call "appRouteConfigsCount"
  const [appRouteConfigsCount, setAppRouteConfigsCount] = useState(0);
  const [isLoginDetails, setIsloginDetails] = useState({});
  const [loading, setLoading] = useState(false);
  let { page } = useParams();

  // Defining component didMount
  useEffect(() => {
    commonIslogin();
    interceptRouteConfigs();
  }, []);

  const commonIslogin = () => {
    props.isLogin({
      callback: (response, data) => {
        if (response) {
          setIsloginDetails(data);
          // _.get(data,'isLoggedIn')===true ?setAppRouteConfigs(routeConfigs)  :setAppRouteConfigs(publicRouteConfigs)

          if (_.get(data, "isLoggedIn") === true) {
            if (_.includes(_.get(data, "types"), 2) == true) {
              adminProfile();
            } else {
              getProfile();
            }
          }
        }
      },
    });
  };

  useEffect(() => {
    setIsloginDetails(_.get(props, "islogindata"));
    if (_.get(props, "islogindata.isLoggedIn") === true) {
      if (_.includes(_.get(props, "islogindata.types"), 2) == true) {
        adminProfile();
      } else {
        getProfile();
      }
    } else {
      setAppRouteConfigs(publicRouteConfigs);
    }
  }, [_.get(props, "checkUpdate")]);

  useEffect(() => {
    const isProtectedRoute = () => {
      const unprotectedPaths = publicRouteConfigs.map(page => page.path);
      return !unprotectedPaths.includes(window.location.pathname);
    };

    if (
      _.get(props, "islogindata.isLoggedIn") === false &&
      _.get(props, "isLoginLoadingInProgress") === false &&
      isProtectedRoute()
    ) {
      navigate("login");
    }
  }, [_.get(props, "isLoginLoadingInProgress")]);

  const getProfile = () => {
    props.profileData({
      params: { expand: "school,user" },
      callback: (res, data) => {
        if (res == true && _.get(data, "data.details.id")) {
          _.get(data, "data.details.type") == 0
            ? setAppRouteConfigs(coachRouteConfigs)
            : _.get(data, "data.details.type") == 2
            ? setAppRouteConfigs(adminRouteConfigs)
            : setAppRouteConfigs(routeConfigs);
          fetchSchoolViewData(_.get(data, "data.details.school"));
        }
      },
    });
  };
  const adminProfile = () => {
    props.adminProfileData({
      callback: (res, data) => {
        if (res == true && _.get(data, "data.details.id")) {
          if (page) {
            navigate(`/dashboard-view?page=${page}`);
          } else {
            navigate("/dashboard-view");
          }

          setAppRouteConfigs(adminRouteConfigs);
          // fetchSchoolViewData(_.get(data, 'data.details.school'))
        }
      },
    });
  };
  // handler for appRouteConfigs
  useEffect(() => {
    setAppRouteConfigsCount(_.add(appRouteConfigsCount, 1));
  }, [appRouteConfigs]);

  const fetchSchoolViewData = (id) => {
    props.getSchoolViewData({
      params: { id: id },
      callback: (res, responseData) => {},
    });
  };

  const interceptRouteConfigs = async () => {
    setLoading(true);
    let modifyRouteConfig = _.clone(appRouteConfigs);
    await new Promise((resolve) => {
      _.forEach(modifyRouteConfig, (routeConfig, key) => {
        if (
          _.chain(routeConfig).get("dashboardWrapper", false).eq(true).value()
        ) {
          const Component = _.chain(routeConfig)
            .clone()
            .get("component")
            .value();
          routeConfig.element = (props) => {
            return (
              <React.Fragment>
                <Component {...props} />
              </React.Fragment>
            );
          };
          if (_.chain(modifyRouteConfig).size().subtract(1).eq(key).value()) {
            resolve();
          }
        }
      });

      setAppRouteConfigs(modifyRouteConfig);
      setLoading(false);
    });
  };

  const render = () => {
    const currentURL = window.location.href;
    const currentURLToArray = currentURL.split("/");
    const currentPage = currentURLToArray[currentURLToArray.length - 1];
    console.log({ currentPage, url: window.location.pathname });
    const listOfPagesThatHidePageHeader = {
      login: 1,
      register: 1,
      "register-successful": 1,
      "continue-registration": 1,
      "express-checkout": 1,
      "forgot-password": 1,
      "login?verification=failed": 1,
      "login?verification=success": 1,
      "": 1,
      "stats-to-print": 1,
      "join-wardbord": 1,
      "leaderboard-print":1
    };
    const listOfPagesThatHideSideBar = {
      // "manage-school": 1,
      // "create-school-admin": 1,
      login: 1,
      register: 1,
      "register-successful": 1,
      "continue-registration": 1,
      "express-checkout": 1,
      "login?verification=failed": 1,
      "login?verification=success": 1,
      "forgot-password": 1,
      "join-wardbord": 1,
      "": 1,
      "stats-to-print": 1,
      "leaderboard-print":1
    };
    const listOfPageThatHidesFooter = {
      register: 1,
      "register-successful": 1,
      "continue-registration": 1,
      "express-checkout": 1,
      "forgot-password": 1,
      "login?verification=failed": 1,
      "login?verification=success": 1,
      "join-wardbord": 1,
      "": 1,
      "stats-to-print": 1,
      "leaderboard-print":1
    };

    return (
      <div>
        <div className="flex flex-col w-screen min-h-screen justify-between">
          {listOfPagesThatHidePageHeader[currentPage] ||
          currentPage.includes("join-wardbord") ||
          currentPage.includes("password-verification") ||
          currentPage.includes("register") ||
          currentPage.includes("login") ? (
            ""
          ) : (
            <PageHeader />
          )}

          <div className="flex w-full justify-between">
            {listOfPagesThatHideSideBar[currentPage] ||
            currentPage.includes("join-wardbord") ||
            currentPage.includes("password-verification") ||
            currentPage.includes("register") ||
            currentPage.includes("login") ? (
              ""
            ) : (
              //  page side bar container
              <div className="md:w-[10%] xl:w-[22%] hide-in-print">
                <PageSidebar />
              </div>
            )}

            {_.size(appRouteConfigs) == 0 || loading ? (
              // pages container
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  height: "100vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spin size="large" />
              </div>
            ) : (
              <div
                className={
                  listOfPagesThatHidePageHeader[currentPage] ||
                  currentPage.includes("join-wardbord") ||
                  currentPage.includes("password-verification")
                    ? "w-full lg:w-80%"
                    : "w-full lg:w-80% xl:w-[80%]"
                }
              >
                <Routes>
                  {_.map(appRouteConfigs, (appRouteConfig) => {
                    const props = {
                      ...appRouteConfig,
                    };

                    return <Route {...props} />;
                  })}
                </Routes>
              </div>
            )}
          </div>
          {listOfPageThatHidesFooter[currentPage] ||
          currentPage.includes("join-wardbord") ||
          currentPage.includes("password-verification") ? (
            ""
          ) : (
            <PageFooter />
          )}
        </div>
      </div>
    );
  };

  return render();
};
const mapStateToProps = (state) => {
  return {
    checkUpdate: _.get(state, "app.account.isLogin.isLoggedIn"),
    isLoginLoadingInProgress: _.get(
      state,
      "app.account.isLoginLoadingInProgress"
    ),
    islogindata: _.get(state, "app.account.isLogin"),
    userProfile: _.get(state, "app.account.profile.data.details"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  isLogin: (details) => dispatch(global.redux.action.account.isLogin(details)),
  profileData: (details) =>
    dispatch(global.redux.action.account.coacheprofile(details)),
  adminProfileData: (details) =>
    dispatch(global.redux.action.account.adminProfile(details)),
  getSchoolViewData: (details) =>
    dispatch(global.redux.action.schools.view(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(RoutesPage);
