import { useState } from "react";
import { Modal, Input, Button, Divider } from "antd";
import { CopyOutlined, PlusOutlined } from "@ant-design/icons";
import cancelThin from "../../assets/cancelThin.svg";
import { connect } from "react-redux";

const InviteCoachModal = ({ visible, onClose, coachProfile, coachCreate }) => {
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [first_name, setFirst_name] = useState(null);
  const [last_name, setLast_name] = useState(null);

  const [emailError, setEmailError] = useState("");
  const [emailList, setEmailList] = useState([]);

  const copyLink = () => {
    const copyText = document.querySelector("#link");
    copyText.select();
    document.execCommand("copy");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const removeCoach = (emailToRemove) => {
    setEmailList(emailList.filter((email) => email !== emailToRemove));
  };

  const handleAddEmail = () => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (email === "" || !emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }
    setEmailList([...emailList, email]);
    setEmail("");
    setEmailError("");
  };

      const onFinish = e => {
        
        // const params = new URLSearchParams(window.location.search);

        // let address_parse = JSON.parse(_.get(e, 'location', {}))
        let details = {
            // type:_.get(props,'coachProfile.type'),
            // school:_.get(props,'coachProfile.school.id'),
            // email: lowercaseFirstLetter(_.get(e, 'email')),
            // first_name: _.get(e, 'first_name'),
            // last_name: _.get(e, 'last_name'),
            // password: _.get(e, 'password'),
            // positions: _.join(_.get(e, 'positions'),','),
            // // location: `${_.get(address_parse, 'lat')}, ${_.get(address_parse, 'lng')}`,
            // // address:_.get(address_parse,'text')
        }  
        coachCreate({
            body: details,
            callback: (res, responseData) => {
                
                if (res == true) {
                    // notification.success(
                    //     { message: 'Success', description: _.get(responseData, 'message') }
                    // )
                   
                }
            }
        });
    };

  const handleSendInvite = () => {
    // TODO: Implement sending invite functionality with the emailList
  };
 
  return (
    <Modal open={visible} onCancel={onClose} footer={null}>
      <div className='flex justify-between items-center mb-3'>
        <span className='text-[#0F1A24] font-[700] text-[1.1vw]'>
          Invite Coach to team
        </span>
      </div>
      <span className='text-[#2E4D6B] font-[400] text-[1.1vw]'>
        Invite Share Link
      </span>
      <div className='flex justify-between items-center'>
        <Input
          id='link'
          className='w-[64%] py-2'
          value='https://example.com/invite-coach'
          readOnly
        />
        <button
          className='w-[34%] border-[#1A91FF] border-[1px] py-2 rounded-md border-solid text-[#1A91FF] flex items-center justify-center text-[1.1vw] font-[700]'
          onClick={copyLink}>
          <i className='fas fa-copy mr-1'></i>
          <span>Copy Link</span>{" "}
        </button>
      </div>
      <div className='w-full flex items-center justify-between mt-[10px]'>
        <div className='w-[43%] h-[2px] bg-[#A9B6C2]'></div>
        <span className='text-[16px] text-[#A9B6C2] font-inter'>or</span>
        <div className='w-[43%] h-[2px] bg-[#A9B6C2]'></div>
      </div>
      <span className='text-[1.1vw] font-[400] text-[#2E4D6B] '>
        First Name
      </span>
      <div className='flex justify-between '>
        <div className='flex flex-col w-[79%]'>
          <Input
            className='w-full py-2'
            placeholder=''
            value={email}
            onChange={handleEmailChange}
          />
          <span className=' text-red-400'>{emailError}</span>
        </div>
        <button
          className='w-[19%] h-[2.9vw] rounded-md bg-[#2E4D6B] text-white flex items-center justify-center text-[1.1vw] font-[700]'
          onClick={handleAddEmail}>
          <PlusOutlined className='mr-2' /> <span>Add</span>{" "}
        </button>
      </div>
      <div>
        {emailList.map((emailItem) => (
          <div
            key={emailItem}
            className='bg-[#E8F4FF] py-2 px-3 flex items-center justify-between mt-2 rounded-md'>
            {" "}
            <span className='text-[#0F1A24] text-[1vw] font-[500]'>
              {emailItem}
            </span>
            <i
              className='fa-solid fa-x cursor-pointer'
              onClick={() => removeCoach(emailItem)}></i>
          </div>
        ))}
      </div>
      <br />
      <Button
        className='bg-[#1A91FF] flex items-center justify-center w-full py-2'
        type='primary'
        onClick={handleSendInvite}
        disabled={!emailList.length}>
        Send Invite
      </Button>
    </Modal>
  );
};
 

const mapStateToProps = state => {
    return{
        coachProfile: state.app.account.profile.data.details,
    }
};

const mapDispatchToProps = dispatch => ({
    coachCreate: details => dispatch(global.redux.action.coaches.create(details)),
    schoolsList: details => dispatch(global.redux.action.schools.list(details)),
    getGridironDetails: details => dispatch(global.redux.action.grades.gridironDetails(details))
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteCoachModal);