import React, { useState, useEffect } from 'react';
import { Modal,   Input,   } from 'antd';
import {
  Table,
  Button, 
  Checkbox,
  Divider, 
  Tooltip,
  Spin, 
  Typography,
} from "antd";
import { 
  CheckCircleOutlined, 
  EyeInvisibleOutlined, 
} from "@ant-design/icons";
import {
  CommentOutlined, 
  DislikeOutlined,
  CalendarOutlined,
  ClockCircleOutlined,

} from "@ant-design/icons";
import _ from "lodash";
import { connect } from 'react-redux';
import { EyeOutlined, AreaChartOutlined    } from "@ant-design/icons";
import moment from 'moment';

const { TextArea } = Input;
const { Title } = Typography;

const renderContent = (value, row, column_name = "") => {
  const obj = {
    children: value,
    props: {},
  };
  if (_.get(row, "partition", false)) {
    if (_.eq(column_name, "name")) {
      obj.props.colSpan = 10;
      obj.children = <div className="PlaysNameTitle">{value}</div>;
    } else {
      obj.props.colSpan = 0;
    }
  }
  return obj;
};
const columns = [
  {
    title: "Player Name",
    dataIndex: "first_name",
    width: "16%",
    align: "center",
    render: (value, row) => `${row.first_name} ${row.last_name}`,
  },

  {
    title: "Position",
    dataIndex: "positions",
    width: "10%",
    align: "center",
    render: (value, row) => value?.toString(),
  },
  {
    title: "Execution",
    dataIndex: "assignment_executed",
    width: "6%",
    align: "center",
    render: (value, row) =>
      renderContent(
        _.chain(value).toInteger().eq(1).value() ? (
          <div className="bluebackgroundchecks">
            <Checkbox checked disabled></Checkbox>
          </div>
        ) : (
          <div className="redBackgroundchecks">
            <Checkbox checked disabled></Checkbox>
          </div>
        ),
        row
      ),
  },
  {
    title: "Effort",
    dataIndex: "great_effort",
    width: "6%",
    align: "center",
    render: (value, row) =>
      renderContent(
        _.chain(value).toInteger().eq(1).value() ? (
          <div className="bluebackgroundchecks">
            <Checkbox checked disabled></Checkbox>
          </div>
        ) : (
          <div className="redBackgroundchecks">
            <Checkbox checked disabled></Checkbox>
          </div>
        ),
        row
      ),
  },

  {
    title: "Flagged",
    dataIndex: "penalties",
    width: "6%",
    align: "center",
    render: (value, row) =>
      renderContent(
        _.chain(value).toInteger().gt(0).value() ? (
          <div className="yellowBackgroundPChecks">
            <Checkbox checked disabled></Checkbox>
          </div>
        ) : (
          <div className="greyBackgroundPChecks">
            <Checkbox checked disabled></Checkbox>
          </div>
        ),
        row
      ),
  },
  
 
];
const VerifyStats = (props) => { 
  const [isModalVisible, setIsModalVisible] = useState(false); 
   // Open/Close modal
   const showModal = () => setIsModalVisible(true);
   const handleCancel = () => setIsModalVisible(false);
 console.log(  'props.playsList' ,props.playsList ,'props.grades',props.grades )

  return ( 
  <div className="flex w-[150px]">
  <button  className='btn flex ' onClick={showModal}>
 <span className='btn px-1 py-0  btn-danger  '><AreaChartOutlined  className='relative bottom-[2px]'/> </span> 
    
              <span className='text-danger font-[600] relative top-[2px] '> Verify Stats</span> 
              </button>      
             
            
 
      {/* Modal to display all comments */}
      <Modal
        title={`Verify Stats on Play: ${props.play?.number}`}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
       
       <Table
                           
                                columns={
                                 columns
                                }
                                dataSource={props.grades}
                            
                                size="middle"
                                pagination={false}
                                className="w-[100%]"
                                bordered={true}
                              />
       
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  coachProfile: state.app.account.profile.data.details,
});

const mapDispatchToProps = (dispatch) => ({
  addComment: (details) => dispatch(global.redux.action.plays.addComment(details)),
  archiveComment: (details) => dispatch(global.redux.action.plays.archiveComment(details)),
  getCommentsForPlay: (details) => dispatch(global.redux.action.plays.getCommentsForPlay(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyStats);
