import React, { useState } from "react";
import save from "../../../assets/img/save.png";
import { Select, message } from "antd";
import PositionDropdown from "./PositionDropdown";
import { LoadingOutlined } from "@ant-design/icons";
import _ from "lodash";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom"; 

const Option = Select.Option;

const EditPlayerInfoModal = ({
  onCancel,
  position,
  firstName,
  lastName,
  email,
  kitNumber,
  height,
  weight,
  classification,
  benchMax,
  squatMax,
  playerId,
  edit,
  editProfile,
  gridironDetails,
  athlete,
  loadAthletesList,
  speed,
}) => {
  const history = useNavigate();
  const [displayPicture, setDisplayPicture] = useState(null);
  const selectDefaultValue = "LB";
  const [loading, setLoading] = useState(false);


  console.log("positionssss", gridironDetails);
  const [selectedText, setSelectedText] = useState(position);
  const [formValues, setFormValues] = useState({
    playerId,
    firstName,
    lastName,
    email,
    position,
    kitNumber,
    height: convertHeightToFt(height),
    weight,
    classification,
    benchMax,
    squatMax,
    athlete,
    speed,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  function convertHeightToCm(heightString) {
    const [feet, inches] = heightString.split("'");
    const feetInCm = parseInt(feet) * 30.48;
    const inchesInCm = parseInt(inches.replace('"', "")) * 2.54;
    const totalCm = feetInCm + inchesInCm;
    return totalCm;
  }

  function convertHeightToFt(cm) {
    const feet = parseInt(cm) / 30.48;
    const parsedFeet = Math.floor(feet);
    const inches = Math.round((feet - parsedFeet) * 12);
    const formattedHeight =
      inches === 12 ? `${parsedFeet + 1}'0"` : `${parsedFeet}'${inches}"`;
    return formattedHeight;
  }

  const capitalizeString = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const updatePlayer = () => {
    console.log(formValues);
    setLoading(true);
    let body = {
      ...formValues,
      height: convertHeightToCm(formValues.height),
      bench_max: formValues.benchMax,
      squat_max: formValues.squatMax,
      positions: formValues?.position,
      last_name: formValues.lastName,
      first_name: formValues.firstName,
      kit_number: formValues.kitNumber,
      sprint_covering_40_yards: formValues.speed,
      id: formValues.athlete,
    };

    if (formValues.kitNumber != kitNumber) {
      body.formValues = kitNumber;
    }
    edit({
      body,
      callback: (res, responseData) => {
        if (res == true) {
          onCancel();
          message.success("Player updated sucessfully");
        }
        console.log("editedplayer ", res, responseData);
        setLoading(false);
        loadAthletesList();
      },
    });
  };

 
  // const onFinish = (credentials, signInType) => {
  //   const query = new URLSearchParams(window.location.search);
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const token = urlParams.get("token");
  //   const email = query.get("email");
  //   if (credentials.password === credentials.confirm) {
  //     let requestParams = {};
  //     requestParams.email = email;
  //     requestParams.token = token;
  //     requestParams.password = credentials.password;
  //     props.changePassword({
  //       body: requestParams,
  //       callback: (res, responseData) => {
  //         if (res == true) {
  //           notification.success({
  //             message: "Success",
  //             description: _.get(responseData, "message"),
  //           });
  //         }
  //         history("/login");
  //       },
  //     });
  //   }
  // };

  // const handleSubmit = (e) => {
  //   const params = new URLSearchParams(window.location.search);
  //   let game_id = params.get("id");
  //   let values = { ...e };
  //   console.log("The progress is correct", values);
  //   values.start_time = addTimeZone(_.get(e, "start_time"));
  //   values.end_time = addTimeZone(_.get(e, "end_time"));
  //   values.game = game_id;
  //   // console.log('details', details);
  //   props.playsCreate({
  //     body: values,
  //     callback: (res, responseData) => {
  //       if (res == true) {
  //         notification.success({
  //           message: "Success",
  //           description: _.get(responseData, "message"),
  //         });
  //         history(`/plays-list?id=${game_id}`);
  //       }
  //       console.log("playsCreate: ", res, responseData);
  //     },
  //   });
  // };

  return (
    <>
      <div className="flex flex-col justify-center">
        <div className="mt-[10px] flex flex-col">
          <span className="mt-[10px] font-inter text-[#2E4D6B] text-[14px] font-normal">
            First Name:
          </span>
          <input
            type="text"
            className="p-2 mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px] border border-[#EAEDF0]"
            value={formValues.firstName}
            onChange={handleChange}
            name="firstName"
          />
          <span className="mt-[10px] font-inter text-[#2E4D6B] text-[14px] font-normal">
            Last Name:
          </span>
          <input
            type="text"
            className=" p-2 mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px] border border-[#EAEDF0]"
            value={formValues.lastName}
            onChange={handleChange}
            name="lastName"
          />

          <span className="mt-[10px] font-inter text-[#2E4D6B] text-[14px] font-normal">
            Uniform Number:
          </span>
          <input
            type="text"
            className=" p-2 mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px] border border-[#EAEDF0]"
            value={formValues.kitNumber}
            onChange={handleChange}
            name="kitNumber"
          />
          <span className="mt-[10px] font-inter text-[#2E4D6B] text-[14px] font-normal">
            Height:
          </span>
          <input
            type="text"
            className=" p-2 mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px] border border-[#EAEDF0]"
            value={formValues.height}
            onChange={handleChange}
            name="height"
            placeholder={`Kindly input password in this format 5'12"`}
          />
          <span className="mt-[10px] font-inter text-[#2E4D6B] text-[14px] font-normal">
            Weight:
          </span>
          <input
            type="text"
            className=" p-2 mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px] border border-[#EAEDF0]"
            value={`${formValues.weight}`}
            onChange={handleChange}
            name="weight"
          />
          <span className="mt-[10px] font-inter text-[#2E4D6B] text-[14px] font-normal">
            Classification:
          </span>
          <input
            type="text"
            className=" p-2 mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px] border border-[#EAEDF0]"
            value={formValues.classification}
            onChange={handleChange}
            name="classification"
          />
          <span className="mt-[10px] font-inter text-[#0F1A24] text-[14px] font-normal">
            Bench Max: <span className="text-[#2E4D6B] text-[12px]">(lbs)</span>
          </span>
          <input
            type="text"
            className=" p-2 mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px] border border-[#EAEDF0]"
            value={`${formValues.benchMax}`}
            onChange={handleChange}
            name="benchMax"
          />
          <span className="mt-[10px] font-inter text-[#0F1A24] text-[14px] font-normal">
            Squat Max: <span className="text-[#2E4D6B] text-[12px]">(lbs)</span>
          </span>
          <input
            type="text"
            className=" p-2 mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px] border border-[#EAEDF0]"
            value={`${formValues.squatMax}`}
            onChange={handleChange}
            name="squatMax"
          />
          <span className="mt-[10px] font-inter text-[#0F1A24] text-[14px] font-normal">
            40 Speed:{" "}
            <span className="text-[#2E4D6B] text-[12px]">(seconds)</span>
          </span>
          <input
            type="text"
            className=" p-2 mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px] border border-[#EAEDF0]"
            value={`${formValues.speed}`}
            onChange={handleChange}
            name="speed"
          />
          <span className="font-inter text-[#2E4D6B] text-[14px] font-normal">
            Position{" "}
          </span>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            defaultValue={position || []}
            placeholder="Select Position(s)"
            className=" mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px]  "
            onChange={(value) =>
              handleChange({
                target: {
                  name: "position",
                  value,
                },
              })
            }
            optionLabelProp="label"
            options={[
              {
                label: (
                  <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
                    Defensive unit
                  </span>
                ),
                options:
                  gridironDetails?.player_positions?.defense[0]?.variables?.map(
                    ({ code, name }) => {
                      return {
                        label: (
                          <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                            <span className="font-bold">{code}</span>{" "}
                            {`(${capitalizeString(name)})`}
                          </span>
                        ),
                        value: code,
                      };
                    }
                  ) || [],
              },
              {
                label: (
                  <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
                    Offensive unit
                  </span>
                ),
                options:
                  gridironDetails?.player_positions?.offense[0]?.variables?.map(
                    ({ code, name }) => {
                      return {
                        label: (
                          <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                            <span className="font-bold">{code}</span>{" "}
                            {`(${capitalizeString(name)})`}
                          </span>
                        ),
                        value: code,
                      };
                    }
                  ) || [],
              },
              {
                label: (
                  <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
                    Special Teams
                  </span>
                ),
                options:
                  gridironDetails?.player_positions?.special_teams[0]?.variables?.map(
                    ({ code, name }) => {
                      return {
                        label: (
                          <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                            <span className="font-bold">{code}</span>{" "}
                            {`(${capitalizeString(name)})`}
                          </span>
                        ),
                        value: code,
                      };
                    }
                  ) || [],
              },
            ]}
          />
          <br />
          <hr />
          {/* [TODO: password reset button] */}
          {/* <span className='mt-[10px] font-inter text-[#0F1A24] text-[14px] font-bold'>
          Password:
        </span>
        <span className='mt-[10px] font-inter text-[#2E4D6B] text-[14px] font-normal'>
          Password(8 ~ 63 characters)
        </span>
        <input
          type='text'
          className=' p-2 mt-[5px] text-[#0F1A24] w-full h-[43px]  rounded-[4px] border border-[#EAEDF0]'
          placeholder='a)g&SF4?KQ%64(p~'
        /> */}
          <div
            className="w-full cursor-pointer border-[2px] border-solid bg-[#1A91FF] flex items-center justify-center h-[43px] mb-[8px] rounded-[4px] mt-[10px]"
            onClick={updatePlayer}
          >
            <div className="flex items-center justify-center">
              {loading ? (
                <>
                  <LoadingOutlined style={{ fontSize: 24, color: "#FFFFFF" }} />{" "}
                  <span className="text-[#FFFFFF] ml-[2%]">Save</span>
                </>
              ) : (
                <>
                  <img src={save} className="mr-[2%]" alt="Save Icon" />
                  <span className="text-[#FFFFFF]">Save</span>
                </>
              )}
            </div>
          </div>
          <div
            className="w-full cursor-pointer border-[2px] border-solid border-[#A40E17] flex items-center justify-center h-[43px] rounded-[4px]"
            onClick={onCancel}
          >
            <div className="w-[15%] flex items-center justify-between font-bold text-[14px] font-inter text-[#A40E17]">
              <i className="fas fa-xmark"></i>
              <span>Cancel</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    coachProfile: state.app.account.profile.data.details,
  };
};
const mapDispatchToProps = (dispatch) => ({
  edit: (details) => dispatch(global.redux.action.athletes.edit(details)),

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPlayerInfoModal);
