import actionType from "../actionTypes";
const reducerName = actionType.reduxUpdateSuperAdminState;

// account actions

export default {
  coachesList: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/admins/get-coaches`,
        },
        actionType: actionType.super_admin.coachesList,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  teamList: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/admins/get-teams`,
        },
        actionType: actionType.super_admin.teamList,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },

  plansList: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/admins/list-plans`,
        },
        actionType: actionType.super_admin.plansList,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  analytics: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/admins/analytics`,
        },
        actionType: actionType.super_admin.analytics,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  createSubscription: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/admins/create-subscription`,
        },
        actionType: actionType.super_admin.createSubscription,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  createPlan: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/admins/create-plan`,
        },
        actionType: actionType.super_admin.createPlan,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  upgradeSubscription: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/admins/upgrade-subscription`,
        },
        actionType: actionType.super_admin.upgradeSubscription,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  modifyPlan: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/admins/modify-plan`,
        },
        actionType: actionType.super_admin.modifyPlan,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  createAffiliate: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "post",
          url: `/admins/create-affiliate`,
        },
        actionType: actionType.super_admin.createAffiliate,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
  getAffiliates: (componentRequest) => {
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: "get",
          url: `/admins/fetch-affiliate-referrals`,
        },
        actionType: actionType.super_admin.getAffiliates,
        reducerName,
        dispatch,
        componentRequest,
      };
      global.api.requestAPI(requestOptions);
    };
  },
};
