import React, { useState } from "react";
import save from "../../../assets/img/save.png";
import PositionDropdown from "../home-page/PositionDropdown";
import {
  Select,
  Dropdown,
  Switch,
  Space,
  Checkbox,
  message,
  Form,
  Input,
} from "antd";
import { Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { connect } from "react-redux";

function AddCoachModalContent({
  onClose,
  loadCoachesList,
  coachProfile,
  gridironDetails,
  coachCreate,
}) {
  const [selectedText, setSelectedText] = useState([]);
  const [email, setEmail] = useState(null);
  const [type, setType] = useState(2);
  const [positions, setPositions] = useState("");
  const [first_name, setFirst_name] = useState(null);
  const [last_name, setLast_name] = useState(null);
  const [password, setPassword] = useState("wardbord");
  const [loading, setLoading] = useState(false);
  console.log("gridirondetails", gridironDetails);

  const capitalizeString = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleInviteCoach = () => {
    // const params = new URLSearchParams(window.location.search);

    // let address_parse = JSON.parse(_.get(e, 'location', {}))
    setLoading(true);
    let details = {
      type,
      school: coachProfile.school.id,
      email,
      first_name,
      last_name,
      password,
      positions: positions.toString(),
      // // location: `${_.get(address_parse, 'lat')}, ${_.get(address_parse, 'lng')}`,
      // // address:_.get(address_parse,'text')
    };
    coachCreate({
      body: details,
      callback: (res, responseData) => {
        if (res == true) {
          loadCoachesList();
          onClose();
        } else {
          message.error("Error creating coach");
        }
        setLoading(false);
      },
    });
  };

  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleInviteCoach}
      initialValues={{
        firstName: first_name,
        lastName: last_name,
        email: email,
        password: password,
        // positions: positions,
        type: type,
      }}
    >
      <div className="flex flex-col justify-center">
        <div className="mt-[10px] flex flex-col">
          <Form.Item
            name="firstName"
            label={
              <span className="mt-[10px] font-inter text-[#2E4D6B] text-[14px] font-normal">
                First Name:
              </span>
            }
            rules={[{ required: true, message: "First name is required" }]}
          >
            <Input
              className="p-2 mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px] border border-[#EAEDF0]"
              onChange={({ target: { value } }) => setFirst_name(value)}
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            label={
              <span className="mt-[10px] font-inter text-[#2E4D6B] text-[14px] font-normal">
                Last Name:
              </span>
            }
            rules={[{ required: true, message: "Last name is required" }]}
          >
            <Input
              className="p-2 mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px] border border-[#EAEDF0]"
              onChange={({ target: { value } }) => setLast_name(value)}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label={
              <span className="mt-[10px] font-inter text-[#2E4D6B] text-[14px] font-normal">
                Email:
              </span>
            }
            rules={[{ required: true, message: "Email is required" }]}
          >
            <Input
              type="email"
              className="p-2 mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px] border border-[#EAEDF0]"
              onChange={({ target: { value } }) => setEmail(value)}
            />
          </Form.Item>
          <Form.Item
            name="password"
            label={
              <span className="mt-[10px] font-inter text-[#2E4D6B] text-[14px] font-normal">
                Create Password:
              </span>
            }
            rules={[{ required: true, message: "Password is required" }]}
          >
            <Input.Password
              className="p-2 mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px] border border-[#EAEDF0]"
              onChange={({ target: { value } }) => setPassword(value)}
            />
          </Form.Item>
          <Form.Item
            name="positions"
            label={
              <span className="mt-[10px] font-inter text-[#2E4D6B] text-[14px] font-normal">
                Position:
              </span>
            }
            rules={[
              { required: true, message: "At least one position is required" },
            ]}
          >
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select Position(s)"
              className="mt-[5px] mb-[10px] text-[#0F1A24] w-full h-[43px] rounded-[4px]"
              onChange={(positions) => setPositions(positions)}
              optionLabelProp="label"
              options={[
                {
                  label: (
                    <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
                      Defensive unit
                    </span>
                  ),
                  options:
                    gridironDetails?.player_positions?.defense[0]?.variables?.map(
                      ({ code, name }) => {
                        return {
                          label: (
                            <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                              <span className="font-bold">{code}</span>{" "}
                              {`(${name})`}
                            </span>
                          ),
                          value: code,
                        };
                      }
                    ) || [],
                },
                {
                  label: (
                    <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
                      Offensive unit
                    </span>
                  ),
                  options:
                    gridironDetails?.player_positions?.offense[0]?.variables?.map(
                      ({ code, name }) => {
                        return {
                          label: (
                            <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                              <span className="font-bold">{code}</span>{" "}
                              {`(${name})`}
                            </span>
                          ),
                          value: code,
                        };
                      }
                    ) || [],
                },
                {
                  label: (
                    <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
                      Special Teams
                    </span>
                  ),
                  options:
                    gridironDetails?.player_positions?.special_teams[0]?.variables?.map(
                      ({ code, name }) => {
                        return {
                          label: (
                            <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                              <span className="font-bold">{code}</span>{" "}
                              {`(${name})`}
                            </span>
                          ),
                          value: code,
                        };
                      }
                    ) || [],
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="type" valuePropName="checked">
            <div className="text-[#2E4D6B] text-[14px] flex items-center">
              <Checkbox
                onChange={({ target: { checked } }) => {
                  const _value = checked ? 1 : 2;
                  console.log("value", checked, "_value", _value);
                  setType(_value);
                }}
              />
              <span className="ml-[8px]">
                Do you want this coach to have admin rights to your account? Ex:
                Off/Def Coordinator, Special Teams
              </span>
            </div>
          </Form.Item>
          <div className="w-full mb-[8px] mt-[10px]">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
              className="w-full h-[43px] rounded-[4px] bg-[#1A91FF] border-1 border-solid border-[#1A91FF]"
            >
              <span className="text-[#FFFFFF]">Create</span>
            </Button>
          </div>
          <div
            className="w-full cursor-pointer border-[2px] border-solid border-[#A40E17] flex items-center justify-center h-[43px] rounded-[4px]"
            onClick={onClose}
          >
            <div className="flex items-center justify-between font-bold text-[14px] font-inter text-[#A40E17]">
              <i className="fas fa-xmark mr-[2%]"></i>
              <span>Cancel</span>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}

const mapStateToProps = (state) => {
  return {
    coachProfile: state.app.account.profile.data.details,
  };
};

const mapDispatchToProps = (dispatch) => ({
  coachCreate: (details) =>
    dispatch(global.redux.action.coaches.create(details)),
  schoolsList: (details) => dispatch(global.redux.action.schools.list(details)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCoachModalContent);
