import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Table,
  Tooltip,
  message,
  Input,
  Card,
  Button,
  List,
  Skeleton,
} from "antd";
import { connect } from "react-redux";
import "./home-page.css";
import Columns from "./Columns";
import plus from "../../../assets/img/plus.svg";

const allowedPosition = {
  QB: "QB",
  OL: "OL",
  WR: "WR",
  TE: "TE",
  RB: "RB",
  OS: "OL",
  DL: "DL",
  LB: "LB",
  S: "S",
  C: "C",
  DB: "C",
  DF: "DL",
  IB: "LB",
  OB: "LB",
  K: "K",
  ST: "ST",
};
const DepthChartGrades = forwardRef(
  (
    {
      gradesUpdate,
      school_id,
      gradesCreate,
      getDepthChartPlayers,
      gradesRemove,
      getDepthChartPlayerGrades,
      setCoachDepthChartId,
      gradesDataLoading,
      sendFeedback,
      play_id,
      game_id,
      feedback = false,
      showPlayersModal,
      data,
      formPlay,
      formPlayNumber,
      grades,
      playsList,
      reloadPlay,
      shared,
      list,
      handleUpdatePlay,
      getStats
    },
    ref
  ) => {
    const [value, setValue] = useState(0);

    const [playerList, setPlayerList] = useState([]);
    const [playerListStock, setPlayerListStock] = useState([]);
    const [playerListLoading, setPlayerListLoading] = useState(false);

    const [playerListGrades, setPlayerListGrades] = useState([]);
    const [editFeedback, setEditFeedback] = useState(true);
    const [seeFeedback, setSeeFeedback] = useState(feedback);
    const [playerListGradesLoading, setPlayerListGradesLoading] =
      useState(false);
    const [playerFeedback, setPlayerFeedback] = useState("");
    const [messageApi, contextHolder] = message.useMessage();
    const [distanceModal, setDistanceModal] = useState(false);
    const [feedbackModal, setFeedbackModal] = useState(false);
    const [feebackItem, setFeebackItem] = useState(false);
    const paramsss = new URLSearchParams(window.location.search);
    let feedback_type = paramsss.get("t");

    useEffect(() => {
      setEditFeedback(true);
      setPlayerFeedback("");
    }, [formPlayNumber]);

    const getFromStore = (key) => {
      const val = sessionStorage.getItem(key) || null;

      // is empty
      if (!val) return null;

      if (typeof val === "undefined") return null;

      // empty []
      if ([].length < 1) return null;

      return JSON.parse(val);
    };
    const fetchDepthChartPlayers = () => {
      const depth_chart_id = data.id;
      const list = getFromStore(`${depth_chart_id}-DepthChartPlayers`);
      if (list) {
        setPlayerListStock(list);
      } else {
        setPlayerListLoading(true);

        getDepthChartPlayers({
          params: { depth_chart_id },
          callback: (res, data) => {
            if (res == true && data) {
              const results = data.data?.rows.map((item, key) => {
                item.disabled = true;
                return item;
              });
              sessionStorage.setItem(
                `${depth_chart_id}-DepthChartPlayers`,
                JSON.stringify(results)
              );
              setPlayerListStock(results);
            }
            setPlayerListLoading(false);
          },
        });
      }
    };

    useEffect(() => {
      setEditFeedback(!feedback);
      if (playerListStock?.length < 1) {
        fetchDepthChartPlayers();
      }
    }, []);

    useEffect(() => {
      fetchDepthChartPlayerGrades();
    }, [playerListStock]);

    useEffect(() => {
      fetchDepthChartPlayerGrades();
    }, [grades]);

    const fetchDepthChartPlayerGrades = () => {
      if (playerListStock?.length > 0) {
        const result = playerListStock.map((item) => {
          const found = grades?.find((row) => {
            return (
              item.id == row.athlete &&
              item.depth_chart_id == row.coach_depth_chart_id
            );
          });

          if (found) {
            found.first_name = item.first_name;
            found.last_name = item.last_name;
            found.rank = item.rank;
            found.disabled = false;
            return found;
          }
          return item;
        });

        setPlayerList(result);
      }
    };
    // const fetchDepthChartPlayerGrades = () => {
    //   setPlayerListLoading(true);
    //   getDepthChartPlayerGrades({
    //     params: { depth_chart_id: data.id, game: game_id, play: play_id },
    //     callback: (res, data) => {
    //       if (res == true && data) {
    //         if (playerListStock?.length > 0) {
    //           const result = playerListStock.map((item) => {
    //             const found = data.data?.rows.find((row) => {
    //               return item.id == row.athlete;
    //             });
    //             if (found) {
    //               found.disabled = false;
    //               return found;
    //             }
    //             return item;
    //           });

    //           setPlayerList(result);
    //           setPlayerListGrades(data.data?.rows);
    //         }
    //       }
    //       setPlayerListLoading(false);
    //     },
    //   });
    // };

    const success = (content) => {
      messageApi.open({
        type: "success",
        content,
      });
    };

    const error = (content) => {
      messageApi.open({
        type: "error",
        content,
      });
    };

    const handleMultipleQuickUpdate = (ids, part, value, modal, modalValue) => {
      const count = ids.length;

      function done(n) {
        setPlayerListLoading(true);
        if (n === 0) {
          setPlayerListLoading(false);
        } else {
          handleQuickUpdateRe(
            ids[count - n].id,
            ids[count - n].athlete,
            part,
            value,
            modal,
            modalValue,
            () => done(n - 1)
          );
        }
      }

      done(count);
    };
    const handleQuickUpdateRe = (
      id,
      athlete,
      part,
      value,
      modal,
      modalValue,
      done
    ) => {
      setPlayerListLoading(true);
      // success("Updating Grades...");
      const params = new URLSearchParams(window.location.search);
      let game_id = params.get("id");
      let details = {
        athlete: athlete,
        game: parseInt(game_id),
        play: play_id,
        id,
      };
      details[part] = value;

      gradesUpdate({
        body: details,
        callback: (res, responseData) => {
          if (res == true) {
            // success("Grades Updated");
            // fetchDepthChartPlayerGrades();
            singleUpdateAthleteListStateRe(responseData.details[0], done);
          } else {
            error("Ops! something bad happened, please reload and try again.");
          }
          modal === true && setDistanceModal(modalValue);
        },
      });
    };
    const handleQuickUpdate = (id, athlete, part, value, modal, modalValue) => {
      setPlayerListLoading(true);
      // success("Updating Grades...");
      const params = new URLSearchParams(window.location.search);
      let game_id = params.get("id");
      let details = {
        athlete: athlete,
        game: parseInt(game_id),
        play: play_id,
        id,
      };
      details[part] = value;
 
      if(part == 'sacks' && (value == 0 || value == 1)) details['tackles'] = 1
    

      gradesUpdate({
        body: details,
        callback: (res, responseData) => {
          if (res == true) {
            // success("Grades Updated");
            // fetchDepthChartPlayerGrades();
            singleUpdateAthleteListState(responseData.details[0]);
          } else {
            error("Ops! something bad happened, please reload and try again.");
          }
          setPlayerListLoading(false);
          modal === true && setDistanceModal(modalValue);
        },
      });
    };

    const handleTrashGrades = (id, athlete, position, coach_depth_chart_id) => {
   setPlayerListLoading(true);
   let details = {
    coach_depth_chart_id,
    position: position, // type == 'direct' ? e : formPositionsType,
    athlete,
    game: parseInt(game_id),
    play: play_id,
    penalties: 0,
    pass_pro_sacks: 1,
    assignment_executed: 1,
    great_effort: 1,
    sacks: null,
    td: null,
    pancakes: null,
    total_carries: null,
    receptions: null,
    catchable_balls: null,
    knockdowns: null,
    pat: null,
    punt_distance: null,
    punts: null,
    completions: null,
    tackles: null,
    turnovers: null,
    field_goals: null,
    field_goal_distance: null,
    interception: null,
    


    missed_tackles: null,
    kicks: null,
     
    missed_field_goal_distance: null,   
    missed_pat: null,
    return_td:null,
    st_td:null,
    negative:null, 

    positive_impact:null,
    behind_line_scrimmage:null,
    caused_fumble:null, 
    yards_after_recovery:null,
     
    yards_after_interception:null,
    pbu_pass_breakup:null,
    safety:null,
    big_hit:null,
    recovered_fumble:null, 

    cut_blocks: null,

    punt_blocks: null,
    fg_blocks: null,

    fumble_lost:null,

    punt_inside_20:null,
    punt_fair_catch:null,

    kickoff_touchback:null,
    kickoff:null, 
    punt_return:null,
    punt_return_fair_catch:null,
    kickoff_return:null,
    kickoff_fair_catch:null,
    onsidekick:null,
    onsidekick_recovered:null,

    yards_after_catch:null,
    yards_after_catch_throw:null,
    yards_after_fumble:null,
    yards_after_fumble_lost:null,
    yards_after_big_hit:null,
    yards_after_safety:null,
    yards_after_contact:null,

  };

      gradesRemove({
        params: {id},
        callback: (res, responseData) => {
          if (res == true) {
          
            gradesCreate({
              body: details,
              callback: (res, responseData) => {
                if (res == true) {

                  const result = playerList.map((item) => {

                    if (item.id == id) {
                      let found = responseData.details; 
                      found.first_name = item.first_name;
                      found.last_name = item.last_name;
                      found.rank = item.rank;
                      found.disabled = false;
                      return found;
                    }
                    return item;
                  });

                  setPlayerList(result); 
                  setPlayerListLoading(false);
                } else {
                  error("Ops! something bad happened, please reload and try again.");
                 
                }
               
              },
            });
          } else {
            error("Ops! something bad happened, please reload and try again.");
          } 
          
        },
      });
   
       
    };

    const handleCreateMultipleGrades = (
      athletes,
      position,
      coach_depth_chart_id,
      odk
    ) => {
      const _athletes = [];

      const plays = playsList
        .filter((i) => i.odk.toLowerCase() == odk.toLowerCase())
        .map((i) => i.id);
      setPlayerListLoading(true);

      const params = new URLSearchParams(window.location.search);
      let game_id = params.get("id");

      athletes.map((athlete) => {
        plays.map((play) => {
          _athletes.push({ athlete, play });
        });
      });

      let details = {
        coach_depth_chart_id,
        position: position, // type == 'direct' ? e : formPositionsType,
        athlete: athletes[0],
        athletes: _athletes,
        game: parseInt(game_id),
        play: plays[0],
        penalties: 0,
        pass_pro_sacks: 1,
        assignment_executed: 1,
        great_effort: 1,
        sacks: null,
        td: null,
        pancakes: null,
        total_carries: null,
        receptions: null,
        catchable_balls: null,
        knockdowns: null,
        pat: null,
        punt_distance: null,
        punts: null,
        completions: null,
        tackles: null,
        turnovers: null, 
        field_goal_distance: null,
        field_goals: null,
        
        interception: null,
        


        missed_tackles: null,
        kicks: null,
         
        missed_field_goal_distance: null,   
        missed_pat: null,
        return_td:null,
        st_td:null,
        negative:null, 

        positive_impact:null,
        behind_line_scrimmage:null,
        caused_fumble:null, 
        yards_after_recovery:null,
         
        yards_after_interception:null,
        pbu_pass_breakup:null,
        safety:null,
        big_hit:null,
        recovered_fumble:null, 

        cut_blocks: null,

        punt_blocks: null,
        fg_blocks: null,

        fumble_lost:null,

        punt_inside_20:null,
        punt_fair_catch:null,

        kickoff_touchback:null,
        kickoff:null, 
        punt_return:null,
        punt_return_fair_catch:null,
        kickoff_return:null,
        kickoff_fair_catch:null,
        onsidekick:null,
        onsidekick_recovered:null,

        yards_after_catch:null,
        yards_after_catch_throw:null,
        yards_after_fumble:null,
        yards_after_fumble_lost:null,
        yards_after_big_hit:null,
        yards_after_safety:null,
        yards_after_contact:null,

        createMultiple: true,
      };

      // if( formPlay.play_type.toLowerCase() == 'pass' && position.toLowerCase() == 'qb' && formPlay.result.toLowerCase() == 'incomplete'){
      //   details.receptions = 1
      // } 
      // if(  position.toLowerCase() == 'qb' && (formPlay.result.toLowerCase() == 'interception' ||  formPlay.result.toLowerCase() == 'int') ){
      //   details.interception = 1
      // } 
      // if( formPlay.result.toLowerCase() == 'complete' ||  formPlay.result.toLowerCase() == 'com' ){
      //   details.receptions = 8
      // } 

      gradesCreate({
        body: details,
        callback: (res, responseData) => {
          if (res == true) {
            success("Players Added to Game Play");
            reloadPlay();
          } else {
            error("Ops! something bad happened, please reload and try again.");
          }
          setPlayerListLoading(false);
        },
      });
    };
    const handleCreateGrades = (athlete, position, coach_depth_chart_id) => {
      setPlayerListLoading(true);
      const params = new URLSearchParams(window.location.search);
      let game_id = params.get("id");

      let details = {
        coach_depth_chart_id,
        position: position, // type == 'direct' ? e : formPositionsType,
        athlete,
        game: parseInt(game_id),
        play: play_id,
        penalties: 0,
        pass_pro_sacks: 1,
        assignment_executed: 1,
        great_effort: 1,
        sacks: null,
        td: null,
        pancakes: null,
        total_carries: null,
        receptions: null,
        catchable_balls: null,
        knockdowns: null,
        pat: null,
        punt_distance: null,
        punts: null,
        completions: null,
        tackles: null,
        turnovers: null,
        field_goals: null,
        field_goal_distance: null,
        interception: null,
        


        missed_tackles: null,
        kicks: null,
         
        missed_field_goal_distance: null,   
        missed_pat: null,
        return_td:null,
        st_td:null,
        negative:null, 

        positive_impact:null,
        behind_line_scrimmage:null,
        caused_fumble:null, 
        yards_after_recovery:null,
         
        yards_after_interception:null,
        pbu_pass_breakup:null,
        safety:null,
        big_hit:null,
        recovered_fumble:null, 

        cut_blocks: null,

        punt_blocks: null,
        fg_blocks: null,

        fumble_lost:null,

        punt_inside_20:null,
        punt_fair_catch:null,

        kickoff_touchback:null,
        kickoff:null, 
        punt_return:null,
        punt_return_fair_catch:null,
        kickoff_return:null,
        kickoff_fair_catch:null,
        onsidekick:null,
        onsidekick_recovered:null,

        yards_after_catch:null,
        yards_after_catch_throw:null,
        yards_after_fumble:null,
        yards_after_fumble_lost:null,
        yards_after_big_hit:null,
        yards_after_safety:null,
        yards_after_contact:null,

      };

      if( formPlay.play_type.toLowerCase() == 'pass' && position.toLowerCase() == 'qb' && formPlay.result.toLowerCase() == 'incomplete'){
        details.receptions = 1
      } 
      if(  position.toLowerCase() == 'qb' && (formPlay.result.toLowerCase() == 'interception' ||  formPlay.result.toLowerCase() == 'int') ){
        details.interception = 1
      } 

      if( position.toLowerCase() == 'qb' && (formPlay.result.toLowerCase() == 'complete' ||  formPlay.result.toLowerCase() == 'com') ){
        details.receptions = 8
      } 
      gradesCreate({
        body: details,
        callback: (res, responseData) => {
          if (res == true) {
            if (feedback_type !== "analytical") {
              singleUpdatePlayerListState(responseData.details);
              setFeedbackModal(!feedbackModal);
              setFeebackItem(responseData.details);
            } else {
              success("Player Added to Game Play");
              singleUpdatePlayerListState(responseData.details);
            }
          } else {
            error("Ops! something bad happened, please reload and try again.");
            console.log("error", responseData);
          }
          setPlayerListLoading(false);
        },
      });
    };

    const handleRemoveGrades = (id) => {
      setPlayerListLoading(true);
      let details = {
        id,
      };
      gradesRemove({
        params: details,
        callback: (res, responseData) => {
          if (res == true) {
            success("Player Removed from Game Play");
            const result = playerList.map((item) => {
              if (item.id == id) {
                item.id = item.athlete;
                item.disabled = true;
                return item;
              }
              return item;
            });
            setPlayerList(result);
          } else {
            error("Ops! something bad happened, please reload and try again.");
          }
          setPlayerListLoading(false);
        },
      });
    };

    useImperativeHandle(ref, () => ({
      handleRemoveGrades,
    }));

    const handleSendFeedback = (position) => {
      const params = new URLSearchParams(window.location.search);
      const game_id = params.get("id");
      if (playerFeedback.length) {
        sendFeedback({
          body: {
            feedback: playerFeedback,
            coach_depth_chart_id: data.id,
            play_id: parseInt(play_id),
            game_id: parseInt(game_id),
            position,
          },
          callback: (res, responseData) => {
            if (res == true) {
              success("Feedback added!");
              setEditFeedback(false);
            } else {
              error(
                "Ops! something bad happened, please reload and try again."
              );
            }
          },
        });
      } else {
        // console.log("empty feedback", playerFeedback);
      }
    };

    const singleUpdatePlayerListState = (gradee) => {
      const result = playerList.map((item) => {
        if (item.id == gradee.athlete) {
          let found = gradee;
          found.first_name = item.first_name;
          found.last_name = item.last_name;
          found.rank = item.rank;
          found.disabled = false;
          return found;
        }
        return item;
      });
      setPlayerList([]);
      setPlayerList(result);
    };
    const singleUpdateAthleteListStateRe = (gradee, done) => {
      setPlayerListLoading(true);
      const result = playerList.map((item) => {
        if (item.athlete == gradee.athlete) {
          let found = gradee;
          found.first_name = item.first_name;
          found.last_name = item.last_name;
          found.rank = item.rank;
          found.disabled = false;
          return found;
        }
        return item;
      });
      setPlayerList([]);
      setPlayerList(result);
      setTimeout(() => {
        setPlayerListLoading(true);
        done();
      }, 2000);
    };
    const singleUpdateAthleteListState = (gradee) => {
      const result = playerList.map((item) => {
        if (item.athlete == gradee.athlete) {
          let found = gradee;
          found.first_name = item.first_name;
          found.last_name = item.last_name;
          found.rank = item.rank;
          found.disabled = false;
          return found;
        }
        return item;
      });
      setPlayerList([]);
      setPlayerList(result);
    };
    const toggleEditFeedback = () => {
      setEditFeedback(!editFeedback);
    };

    return (
      <div className="depthchart py-[0.3%] font-inter text-base xl:text-lg font-bold">
        {contextHolder}
        <div className="w-full flex bg-[black] text-[white] items-center justify-between rounded-[5px] p-[5px]">
          <h6 className="mb-0">
           Play
               <span>
                {formPlayNumber < 10 ? ` 0${formPlayNumber}` : ` ${formPlayNumber}`}
              </span> : {data.code} ({data.name}) Chart
          </h6>
          <div className="flex w-[70%] items-center">
             
            <span className="text-xs font-normal xl:text-sm ml-[1%]">
              {" "}
              Group Feedback:{" "}
            </span>
            {shared && (
              <span
                className="bg-[#EAEDF0] text-black items-center px-[1%] text-xs xl:text-sm flex w-[65%] ml-[1%] h-[43px] rounded-md font-medium"
              >
                {feedback ||
                  playerFeedback ||
                  "Insert your group feedback here."}
              </span>
            )}
            {!shared &&
              (editFeedback ? (
                <span
                  className="bg-[#EAEDF0] w-[65%] font-medium text-black ml-[1%] h-[43px] rounded-md xl:text-sm px-[1%] flex items-center"
                >
                  {feedback ||
                    playerFeedback ||
                    "Insert your group feedback here."}
                </span>
              ) : (
                <Input
                  defaultValue={feedback || playerFeedback}
                  placeholder={
                    feedback ||
                    playerFeedback ||
                    "Insert your group feedback here."
                  }
                  onChange={({ target: { value } }) => setPlayerFeedback(value)}
                  onMouseLeave={() =>
                    handleSendFeedback(allowedPosition[data.code])
                  }
                  // onBlur={() => handleSendFeedback(allowedPosition[data.code])}
                  // onKeyDown={() => handleSendFeedback(allowedPosition[data.code])}
                  className="bg-[#EAEDF0] w-[65%] ml-[1%] h-[43px]"
                  autoFocus={editFeedback}
                />
              ))}
            <div className="bg-[#1A91FF] h-[43px] w-[43px] rounded-[4px] flex items-center justify-center ml-[1%]">
              <Tooltip title='Click to add or edit feedback'>
                <img
                 onClick={toggleEditFeedback}
                  src={plus}
                  style={{ cursor: "pointer" }}
                  className="w-[50%]"
                />
              </Tooltip>
            </div>
          </div>
        </div>

        {/* tablehead */}
        <Columns
        getStats={getStats}
          school_id={school_id}
          hasFeedback={feedback || playerFeedback ? true : false}
          groupFeedback={feedback || playerFeedback}
          handleCreateGrades={handleCreateGrades}
          handleCreateMultipleGrades={handleCreateMultipleGrades}
          handleRemoveGrades={handleRemoveGrades}
          handleQuickUpdate={handleQuickUpdate}
          handleMultipleQuickUpdate={handleMultipleQuickUpdate}
          handleTrashGrades={handleTrashGrades}
          setPlayerList={setPlayerList}
          playerListGradesLoading={playerListGradesLoading}
          playerListGrades={playerListGrades}
          playerListLoading={playerListLoading || gradesDataLoading}
          data={data}
          grades={grades}
          playerList={playerList}
          formPlay={formPlay}
          distanceModal={distanceModal}
          setDistanceModal={setDistanceModal}
          feeback={feedbackModal}
          feebackItem={feebackItem}
          setFeebackItem={setFeebackItem}
          setFeeback={setFeedbackModal}
          loadAllPlayerList={list}
          handleUpdatePlay={handleUpdatePlay}
        />
      </div>
    );
  }
);

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getDepthChartPlayerGrades: (details) =>
    dispatch(global.redux.action.depth_chart.grades(details)),
  getDepthChartPlayers: (details) =>
    dispatch(global.redux.action.depth_chart.players(details)),
    getStats: (details) => dispatch(global.redux.action.games.getStats(details)),
  gradesCreate: (details) =>
    dispatch(global.redux.action.grades.create(details)),
  gradesRemove: (details) =>
    dispatch(global.redux.action.grades.remove(details)),
  gradesUpdate: (details) =>
    dispatch(global.redux.action.grades.update(details)),
  sendFeedback: (details) =>
    dispatch(global.redux.action.depth_chart.sendFeedback(details)),
    list: (details) => dispatch(global.redux.action.athletes.infoList(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DepthChartGrades);
