import React from "react";
import trophy from "../../../assets/img/trophy.svg";

function LeaderboardComponent({ title, playerStats, statistics }) {
  return (
    <div className="border-[1px] border-[#EAEDF0] rounded-lg border-solid w-[252px] lg:max-h-[300px] xl:w-[23%]">
      <div className="p-[12px] h-[52px] bg-[#0F1A24] text-[white] text-sm font-bold rounded-t-lg w-full flex items-center">
        <img src={trophy} alt="trophy" />
        <span className="ml-4">{title}</span>
      </div>
      <div className="lg:h-[248px] lg:overflow-y-scroll text-xs rounded-b-lg w-full">
        {playerStats
          ?.filter((player) =>
            statistics?.some(
              (stat) =>
                Number(player[stat]) !== 0 &&
                player[stat] !== undefined
            )
          )
          .sort((a, b) => {
            for (let stat of statistics) {
              const statA = parseFloat(a[stat]) || 0;
              const statB = parseFloat(b[stat]) || 0;
              if (statA !== statB) {
                return statB - statA;
              }
            }
            return 0;
          })
          ?.map((player, index) => (
            <div
              key={index}
              className={`flex items-center ${
                index === 0 ? "bg-[#F0A922]" : "bg-[#FCFCFD]"
              } text-[#0F1A24] w-full font-[400]`}
            >
              <div
                className="p-[12px] flex items-center justify-center w-[20%]"
                style={{ border: "1px solid #EAEDF0" }}
              >
                <span>{index + 1}</span>
              </div>
              <div
                className="p-[12px] flex items-center justify-center w-[60%]"
                style={{ border: "1px solid #EAEDF0" }}
              >
                <span>{`${player.first_name} ${player.last_name}`}</span>
              </div>
              {statistics?.map((stat, idx) => (
                <div
                  key={idx}
                  className="p-[12px] flex items-center justify-center w-[20%]"
                  style={{ border: "1px solid #EAEDF0" }}
                >
                  <span>{player[stat]}</span>
                </div>
              ))}
            </div>
          ))}
        {playerStats?.filter((player) =>
          statistics?.some(
            (stat) => Number(player[stat]) !== 0 && player[stat] !== undefined
          )
        ).length === 0 && (
          <div className="text-center py-4 text-[#0F1A24] text-lg flex items-center justify-center h-full">
            No data available
          </div>
        )}
      </div>
    </div>
  );
}

export default LeaderboardComponent;
