import actionType from '../actionTypes';
import _ from 'lodash';
const reducerName = actionType.reduxUpdateSiteState;

export default {

    gridironDetails: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/warboard/site/gridiron-details`
                },
                actionType: actionType.site.gridironDetails,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
};


// import actionType from '../actionTypes';
// import positionData from '../../../containers/position-data';

// const reducerName = actionType.reduxUpdateSiteState;

// export default {
//     gridironDetails: (componentRequest) => {
//         return dispatch => {
//             dispatch({
//                 type: actionType.site.gridironDetails,
//                 payload: positionData, 
//                 reducerName,
//                 componentRequest
//             });
//             if (componentRequest && typeof componentRequest.callback === 'function') {
//                 componentRequest.callback(true, positionData);
//             }
//         }
//     },
// };
