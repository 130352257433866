import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Select } from "antd";
import { connect } from "react-redux";

const { Option } = Select;
function SeasonsListSelect(props) {
  const [seasonsList, setSeasonsList] = useState([]);

  useEffect(() => {
    loadSeasonsList();
  }, []);
  const loadSeasonsList = () => {
    const list = JSON.parse(sessionStorage.getItem("seasonsList") || "");
    if (list) {
      setSeasonsList(list);
    }
    props.seasonsList({
      params: {
        sort: "id.desc",
        limit: 1000,
      },
      callback: (res, data) => {
        if (res === true) {
          const result = data?.data?.items;
          setSeasonsList(data?.data?.items);
          if (props.setSeasonsList) props.setSeasonsList(data?.data?.items);
          sessionStorage.setItem("seasonsList", JSON.stringify(data?.data?.items));
        }
      },
    });
  };

  return (
    <div className="w-[200px] bg-[#ffffff] h-[43px] ml-[8px] border-[1px] border-solid border-[#EAEDF0] font-bold font-inter flex items-center justify-center rounded-[4px]">
      <Select
        bordered={false}
        defaultValue={
          props && props?.defaultValue ? props?.defaultValue : seasonsList?.[0]?.id
        }
        placeholder={
          props && props?.defaultValue
            ? seasonsList?.find((i) => i?.id == props?.defaultValue)?.title
            : seasonsList?.[0]?.title || "Seasons"
        }
        onChange={props?.onChange}
        className="w-[100%] text-[14px]"
      >
        {_.map(props?.seasonsLists || seasonsList, ({ id, title }, index) => {
          return (
            <Option
              value={id}
              key={index}
              className="text-[14px] font-bold font-inter"
            >
              {title}
            </Option>
          );
        })}
      </Select>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    coachProfile: _.get(state, "app.account.profile.data.details"),
    schoolDetails: _.get(state, "app.schools.view.data"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  seasonsList: (details) => dispatch(global.redux.action.seasons.list(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeasonsListSelect);
