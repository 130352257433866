import React, { useState, useReducer, useEffect } from "react";
import { Card, Table, Tooltip, message, Badge } from "antd";
import infoIcon from "../../assets/img/info-empty.svg";
import pencil from "../../assets/img/pencil.svg";
import trash from "../../assets/img/trash.svg";
import AddStats from "./modal/addStats";
import ModalModules from "../coach/plays-atheletes/modals";
import {
  defaultModalReducerStates,
  modalReducer,
} from "../coach/plays-atheletes/Columns";
import { connect } from "react-redux";
import _ from "lodash";
import ComingSoon from "../../components/Modals/ComingSoon";
import crystal from "../../assets/img/codepen.svg";
import stats from "../../assets/img/stats-square-up.svg";
import WeeklyAward from "./modal/weeklyAward";
import Profile from "./Profile";

function CustomStats(props) {
  const keys = [
    "offensive_skills_of_the_week",
    "offensive_lineman_of_the_week",
    "defensive_skills_of_the_week",
    "defensive_lineman_of_the_week",
    "special_teams_of_the_week",
    "hit_man_of_the_week",
  ];
  const defaultAwardTitles = {
    offensive_skills_of_the_week: "Offensive Skills Player of the week",
    offensive_lineman_of_the_week: "Offensive Lineman of the week",
    defensive_skills_of_the_week: "Defensive Skills Player of the week",
    defensive_lineman_of_the_week: "Defensive Lineman of the week",
    special_teams_of_the_week: "Special Teams Player of the week",
    hit_man_of_the_week: "Hit Man of the week",
  };

  const getParsedTitles = (titles) => {
    if (!titles) return [];
    try {
      return JSON.parse(titles);
    } catch (error) {
      return [];
    }
  };

  const awardTitlesArray = getParsedTitles(
    props?.coachProfile?.school?.wardbord_column_titles || "[]"
  );

  console.log(props.coachProfile, "coach profile");

  const initialAwardTitles = keys.reduce((acc, key, index) => {
    acc[key] = awardTitlesArray[index] || defaultAwardTitles[key];
    return acc;
  }, {});

  const [page, setPage] = useState("stats");
  const [addStats, setAddStats] = useState(false);
  const [editStats, setEditStats] = useState(false);
  const [customStats, setCustomStats] = useState({});
  const [modalState, dispatchModalState] = useReducer(
    modalReducer,
    defaultModalReducerStates
  );
  const [school_id, setSchool_id] = useState(null);
  const [odk, setOdk] = useState("");
  const [addingStats, setAddingStats] = useState(false);
  const [oData, setOdata] = useState([]);
  const [dData, setDdata] = useState([]);
  const [kData, setKdata] = useState([]);
  const [customStatsId, setCustomStatsId] = useState(null);
  const [productionModal, setProductionModal] = useState(false);
  const [openWeeklyAwardModal, setOpenWeeklyAwardModal] = useState(false);
  const [updatingAwardTitle, setUpdatingAwardTitle] = useState(false);
  const [awardTitles, setAwardTitles] = useState(initialAwardTitles);
  const [indexKey, setIndexKey] = useState(1);
  const [editMode, setEditMode] = useState(false);

  const fetchSchoolViewData = (id) => {
    props.getSchoolViewData({
      params: { id: id },
      callback: (res, responseData) => {
        if (res === true) {
        }
      },
    });
  };
  useEffect(() => {
    if (_.get(props, "coachProfile.id")) {
      console.log(
        JSON.parse(_.get(props, "coachProfile.school.wardbord_column_titles")),
        "schooldetail"
      );
      setSchool_id(_.get(props, "coachProfile.school.id"));
      console.log("school id", school_id);
    }
  }, [_.get(props, "coachProfile")]);

  const getProfile = async () => {
    props.profileData({
      params: { expand: "school,user" },
      callback: async (res, data) => {
        if (res == true && _.get(data, "data.details.id")) {
          // await setuserProfile(_.get(data, "data.details"));
          // await setuserProfileMeta(_.get(data, "data.meta"));
          // setmenuVisible(true);
          console.log("getProfile", data);
        }
      },
    });
  };

  const handleUpdateWeeklyAwardTitle = async () => {
    setUpdatingAwardTitle(true);
    const awardTitlesArray = Object.entries(awardTitles).map(([key, value]) => {
      return value;
    });
    await props.updateSchool({
      body: {
        wardbord_column_titles: JSON.stringify(awardTitlesArray),
        id: school_id,
      },
      callback: (res, data) => {
        if (res === true) {
          message.success("Award title updated sucessfully");
          setEditMode(false);
          getProfile();
        }
        fetchSchoolViewData(school_id);
        setUpdatingAwardTitle(false);
        setOpenWeeklyAwardModal(false);
      },
    });
  };

  const column = [
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
      width: "40%",
      padding: 0,
      render: (text, item) => (
        <div
          className="flex items-center gap-2 w-[70px] "
          style={{ overflowWrap: "anywhere" }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Input Type",
      dataIndex: "input_type",
      key: "input_type",
      width: "20%",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      width: "40%",
      render: (text, item) => (
        <>
          {item.odk == "o" ? (
            <div className="flex items-center w-[50px] mb-3 mx-auto">
              {item.index == 1 ? (
                <Badge
                  count={"QB"}
                  style={{ backgroundColor: "#52c41a", margin: "auto" }}
                />
              ) : (
                ""
              )}

              {item.index == 3 ? (
                <Badge
                  count={"WR,TE..."}
                  style={{
                    backgroundColor: "#52c41a",
                    fontSize: "8px",
                    margin: "auto",
                  }}
                />
              ) : (
                ""
              )}

              {item.index == 2 ? (
                <Badge
                  count={"OL"}
                  style={{ backgroundColor: "#52c41a", margin: "auto" }}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          <div className="flex items-center gap-3 ">
            <Tooltip title="Edit">
              <img
                src={pencil}
                className="w-[20%] ml-auto cursor-pointer"
                onClick={() => {
                  setEditStats(true);
                  setCustomStats(item);
                  setCustomStatsId(text);
                  setOdk(item.odk);
                }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <img
                src={trash}
                className="w-[20%] mr-auto cursor-pointer"
                onClick={() => handleDeleteStats(text)}
              />
            </Tooltip>
          </div>
        </>
      ),
    },
  ];
  // const dataSource = [
  //   {
  //     key: "1",
  //     label: "Good Game?",
  //     inputType: "Toggle",
  //   },
  //   {
  //     key: "2",
  //     label: "Powerful Hit",
  //     inputType: "numeric input field.",
  //   },
  // ];

  const odkValue = [
    {
      key: "1",
      label: "Offensive",
      value: "o",
      customStats: oData,
    },
    {
      key: "2",
      value: "d",
      label: "Defensive",
      customStats: dData,
    },
    {
      key: "3",
      label: "Special Team",
      value: "k",
      customStats: kData,
    },
  ];

  useEffect(() => {
    handleGetStats();
  }, []);

  const handleCreateStats = (label, input_type, indexK = null) => {
    console.log("Starting handleCreateStats...");
    setAddingStats(true);
    console.log("Params:", label, input_type, odk, indexKey, school_id);
    props.createStats({
      body: {
        label,
        input_type,
        school: school_id,
        odk,
        index: indexK || indexKey,
      },
      callback: (res, responseData) => {
        console.log("Callback received:", res, responseData);

        if (res === true) {
          console.log("Stats created successfully:", responseData);
          message.success("Stats created successfully!");
          setAddStats(false);
          handleGetStats();
        } else {
          console.log("Error creating stats:", responseData);
        }
        setAddingStats(false);
      },
    });
  };

  const handleGetStats = () => {
    console.log("Starting handleGetStats...");
    props.getStats({
      params: { school: _.get(props, "coachProfile.school.id") },
      callback: (res, responseData) => {
        console.log("Callback received:", res, responseData?.data);
        if (res === true) {
          console.log("Stats retrieved successfully:", responseData?.data);
          const oDatas = responseData?.data.filter((item) => {
            return item.odk === "o";
          });
          const dDatas = responseData?.data.filter((item) => {
            return item.odk === "d";
          });
          const kDatas = responseData?.data.filter((item) => {
            return item.odk === "k";
          });
          setOdata(oDatas);
          setDdata(dDatas);
          setKdata(kDatas);
          console.log("oDatas", oDatas, "dDatas", dDatas, "kDatas", kDatas);
        } else {
          console.log("Error retrieving stats:", responseData);
        }
        setEditStats(false);
      },
    });
  };

  const handleEditStats = (label, input_type, indexK = null) => {
    props.upDateStats({
      body: {
        label,
        input_type,
        school: school_id,
        odk,
        index: indexK || indexKey,
      },
      params: { id: customStatsId },
      callback: (res, responseData) => {
        if (res === true) {
          console.log("Stats updated successfully:", responseData);
          message.success("Stats updated successfully!");
          handleGetStats();
        } else {
          console.log("Error updating stats:", responseData);
        }
      },
    });
  };

  const handleDeleteStats = (id) => {
    console.log("Starting handleDeleteStats...");
    props.deleteStats({
      params: { id },
      callback: (res, responseData) => {
        console.log("Callback received:", res, responseData);
        if (res === true) {
          console.log("Stats deleted successfully:", responseData);
          message.success("Stats deleted successfully!");
          handleGetStats();
        } else {
          console.log("Error deleting stats:", responseData);
        }
      },
    });
  };

  return (
    <div className="w-[98%] p-[1%]">
      <span className="text-[23px] xl:text-[1.7vw] font-bold">Settings</span>
      <Card bodyStyle={{ padding: 12 }}>
        <div className="flex items-center">
          <button
            className={` px-4 py-[0.65rem] ${
              page == "profile"
                ? " text-[#ffffff] bg-[#2E4D6B]"
                : " text-[#2E4D6B]  bg-[#F3F5F7]"
            }  font-bold text-[14px] cursor-pointer focus:outline-0 rounded-l-[8px]`}
            onClick={() => setPage("profile")}
          >
            My Profile
          </button>
          <button
            className={`px-4 py-[0.65rem] ${
              page == "stats"
                ? "text-[#ffffff] bg-[#2E4D6B]"
                : "  text-[#2E4D6B] bg-[#F3F5F7]"
            }   font-bold text-[14px] cursor-pointer focus:outline-0`}
            onClick={() => setPage("stats")}
          >
            {" "}
            Custom Stats
          </button>
          <button
            className={`px-4 py-[0.65rem] ${
              page == "weekly-award-settings"
                ? "text-[#ffffff] bg-[#2E4D6B]"
                : "  text-[#2E4D6B] bg-[#F3F5F7]"
            }   font-bold text-[14px] cursor-pointer focus:outline-0`}
            onClick={() => {
              setPage("weekly-award-settings");
              setOpenWeeklyAwardModal(true);
            }}
          >
            {" "}
            Weekly Award Settings
          </button>
          <button
            className={`px-4 py-[0.65rem] ${
              page == "production-settings"
                ? "text-[#ffffff] bg-[#2E4D6B]"
                : "  text-[#2E4D6B] bg-[#F3F5F7]"
            }   font-bold text-[14px] cursor-pointer focus:outline-0 rounded-r-[8px]`}
            onClick={() => {
              setPage("production-settings");
              setProductionModal(true);
            }}
          >
            {" "}
            Production Settings
          </button>
        </div>
        {page === "stats" && (
          <>
            <Card
              bodyStyle={{ backgroundColor: "#F3F5F7", padding: 10 }}
              className="mt-3"
            >
              <div className="flex items-center gap-2 text-sm text-[#2E4D6B]">
                <img src={infoIcon} />
                <span className="font-bold">
                  Custom stats are accessible on the "Season Analytics" screen
                  and can be found by selecting the "Group" tab{" "}
                </span>
              </div>
            </Card>
            <div className="flex flex-wrap gap-4 mt-3">
              {odkValue?.map((item, index) => (
                <div className="w-[370px] xl:w-[32%]" key={index}>
                  <Card
                    bodyStyle={{ padding: 12, border: "1px solid #ECECF4" }}
                    className="w-full"
                  >
                    <div className="flex items-center justify-between w-full">
                      <span className="text-base font-bold">
                        {item?.label} Stats
                      </span>
                      <button
                        className="text-[#1A91FF] text-xs font-normal"
                        onClick={() =>
                          dispatchModalState({ type: "defensive-modal" })
                        }
                      >
                        Preview
                      </button>
                    </div>
                    <div
                      className="w-full mt-4 flex flex-col pb-3"
                      style={{ border: "1px solid #F3F5F7" }}
                    >
                      <Table
                        columns={column}
                        dataSource={item?.customStats}
                        bordered
                        pagination={false}
                      />
                      {console.log(item, "-----")}
                      {item?.customStats?.length <
                      (item?.value == "o" ? 3 : 2) ? (
                        <button
                          className="text-[#1A91FF] text-xs font-normal mt-3 mx-auto focus:outline-none"
                          onClick={() => {
                            setAddStats(true);
                            setOdk(item?.value);

                            const index = item?.customStats.length + 1;

                            setIndexKey(index);
                          }}
                        >
                          + Add Custom Stat
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </Card>
                </div>
              ))}
            </div>
          </>
        )}
        {console.log("odkValue", odkValue)}
        {addStats && (
          <AddStats
            visible={addStats}
            setVisible={setAddStats}
            title="Add Custom Stat"
            buttonText="Add"
            stats={customStats}
            odk={odk}
            odkValue={odkValue}
            setStats={setCustomStats}
            onFinish={handleCreateStats}
            loading={addingStats}
          />
        )}
        {editStats && (
          <AddStats
            visible={editStats}
            odkValue={odkValue}
            odk={odk}
            setVisible={setEditStats}
            title="Edit Custom Stat"
            buttonText="Save"
            stats={customStats}
            setStats={setCustomStats}
            onFinish={handleEditStats}
          />
        )}
        <ComingSoon
          title="Production"
          imgOne={crystal}
          pOne={
            "Currently being developed. This will be ready in spring of 2025"
          }
          pTwo={
            "This feature streamlines player production by integrating all systems used to evaluate and communicate performance through simple yet comprehensive workflows."
          }
          open={productionModal}
          onClose={() => setProductionModal(false)}
          imgTwo={stats}
          bg={"#E8F4FF"}
          textColor={"#1A91FF"}
        />
        {/* <ModalModules
          state={modalState}
          school_id={""}
          loadAllPlayerList={() => {}}
          item={{}}
          update={() => {}}
          dispatch={dispatchModalState}
          close={() =>
            dispatchModalState({
              type: "close-modal",
            })
          }
          cutomStat={true}
        /> */}
        {page === "weekly-award-settings" && (
          <div className="w-[50%] mx-auto my-12">
            <WeeklyAward
              awardTitles={awardTitles}
              setAwardTitles={setAwardTitles}
              handleUpdateWeeklyAwardTitle={handleUpdateWeeklyAwardTitle}
              loading={updatingAwardTitle}
              editMode={editMode}
              setEditMode={setEditMode}
            />
          </div>
        )}
        {page === "profile" && (
          <div className="w-[100%] mt-4 mb-8">
            <Profile
              gridironDetails={props.gridironDetails}
              coachProfile={props?.coachProfile}
              updateCoach={props.updateCoach}
              getProfile={getProfile}
            />
          </div>
        )}
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    coachProfile: _.get(state, "app.account.profile.data.details"),
    gridironDetails: _.get(state, "app.site.gridironDetails.data.data", {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  createStats: (details) =>
    dispatch(global.redux.action.games.createStats(details)),
  getStats: (details) => dispatch(global.redux.action.games.getStats(details)),
  upDateStats: (details) =>
    dispatch(global.redux.action.games.updateStats(details)),
  deleteStats: (details) =>
    dispatch(global.redux.action.games.deleteStats(details)),
  updateSchool: (details) =>
    dispatch(global.redux.action.schools.update(details)),
  getSchoolViewData: (details) =>
    dispatch(global.redux.action.schools.view(details)),

  profileData: (details) =>
    dispatch(global.redux.action.account.coacheprofile(details)),
  updateCoach: (details) =>
    dispatch(global.redux.action.coaches.update(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomStats);
