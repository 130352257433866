import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import {
  Table,
  Button,
  Select,
  Pagination,
  InputNumber,
  Card,
  Checkbox,
  Tooltip,
  Input,
  Modal,
  message,
  Popover,
} from "antd";
import {
  UserOutlined,
  CloseOutlined,
  MoreOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import CoachViewModalComponent from "./coach-view.js";
import { connect } from "react-redux";
import "./coaches.css";
import _, { filter, isEmpty } from "lodash";
import addplayer from "../../../assets/img/addplayer.png";
import pencil from "../../../assets/img/pencil.svg";
import eye from "../../../assets/img/eye-empty.svg";
import InviteCoachModal from "../../../components/Modals/InviteCoachModal.js";
import trashicon from "../../../assets/img/trash.png";
import searchicon from "../../../assets/img/search.png";
import AddCoachModalContent from "./AddCoachModalContent.js";
import EditCoachModal from "./EditCoachModal.js";
import DeleteModals from "../../coach/evaluations/DeletesModal";
import ChangePasswordModal from "../home-page/ResetPassword.js";

const Option = Select.Option;

const CoachesListComponent = (props) => {
  const [TableData, setTableData] = useState("");
  const [showModal, setshowModal] = useState(false);
  const [coachesList, setCoachesList] = useState([]);
  const [coachesListMeta, setCoachesListMeta] = useState({});
  const [coachesListLoading, setCoachesListLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [inviteCoachModalVisible, setInviteCoachModalVisible] = useState(false);
  const [addCoachModal, setAddCoachModal] = useState(false);
  const [editCoachModal, setEditCoachModal] = useState(false);
  const [coach, setCoach] = useState({});
  const [searchText, setSearchText] = useState("");
  const [coachId, setCoachId] = useState("");
  const [deleteCoachModal, setDeleteCoachModal] = useState(false);
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("wardbord");
  const [passwordResetLoading, setPasswordResetLoading] = useState(false);

  const filteredCoaches = coachesList.filter(
    (coach) =>
      coach?.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
      coach?.last_name.toLowerCase().includes(searchText.toLowerCase())
  );

  const showModalView = (item) => {
    setshowModal(true);
    setTableData(item);
  };

  const closeEditModal = () => {
    setEditCoachModal(false);
  };

  const openAddCoach = () => {
    setAddCoachModal(true);
  };
  const closeAddCoach = () => {
    setAddCoachModal(false);
  };

  const handleCloseModal = () => {
    setPasswordModalVisible(false);
  };

  const handleSavePassword = () => {
    setPasswordResetLoading(true);
    props.changePassword({
      body: {
        email: coach?.email,
        isCoach: true,
        password: newPassword,
      },
      callback: (res, data) => {
        if (res == true) {
          console.log("update password true", data);
          message.success("Password updated sucessfully");
        }
        setPasswordResetLoading(false);
      },
    });
  };

  const columns = [
    {
      title: () => <Checkbox disabled />,
      width: "5%",
      align: "center",
      dataIndex: "isChecked",
      key: "isChecked",
      render: (text, record) => (
        <Checkbox
          defaultChecked={record.isChecked}
          onChange={(e) => {
            record.isChecked = e.target.checked;
            if (e.target.checked) {
              setCoachId(record.id);
            } else {
              setCoachId({});
            }
          }}
        />
      ),
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "10%",
      align: "center",
      render: (text, record) => {
        return (
          <div className="flex">
            {record.type === 1 && (
              <Tooltip title="Admin">
                <span className="text-[red]">*</span>
              </Tooltip>
            )}
            <span className="ml-1">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      width: "12%",
      key: "last_name",
      align: "center",
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "15.5%",
      align: "center",
    },
    {
      title: "Positions",
      dataIndex: "positions",
      key: "positions",
      width: "8%",
      align: "center",
      render: (text, item) => (
        <div className="status flex items-center justify-center h-full text-[8px] md:text-[10px] xl:text-[11px] ">
          {text?.toString()}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "5%",
      align: "center",
      render: (text, item) => (
        <section className="flex items-center justify-center">
          <Tooltip title="More">
            <Popover
              arrow={false}
              trigger="click"
              placement="bottom"
              content={
                <div className="flex flex-col w-[200px]">
                  <div
                    className="flex cursor-pointer items-center w-full text-[12px] font-inter"
                    onClick={() => {
                      setEditCoachModal(true);
                      setCoach(item);
                      console.log("each coach is", item);
                    }}
                  >
                    <img src={pencil} className=" w-[8%]" />
                    <span className="ml-[3%]">Edit Coach</span>
                  </div>
                  <div
                    className="flex mt-2 cursor-pointer items-center w-full text-[12px] font-inter"
                    onClick={() => {
                      setCoachId(item.id);
                      setDeleteCoachModal(true);
                    }}
                  >
                    <img src={trashicon} className=" w-[8%]" />
                    <span className="ml-[3%]">Delete/Archive Coach</span>
                  </div>
                  <div
                    className="flex  mt-2 cursor-pointer items-center w-full text-[12px] font-inter"
                    onClick={() => {
                      setCoach(item);
                      setPasswordModalVisible(true);
                    }}
                  >
                    <ReloadOutlined style={{ fontSize: "15px" }} />
                    <span className="ml-[3%]">Reset Coach's Password</span>
                  </div>
                </div>
              }
            >
              <MoreOutlined style={{ fontSize: "20px" }} />
            </Popover>
          </Tooltip>
        </section>
      ),
    },
  ];

  useEffect(() => {
    if (_.get(props, "coachProfile.id")) {
      // fetchCoachViewData(_.get(props, 'coachProfile.coaches'))
      loadCoachesList();
    }
  }, [_.get(props, "coachProfile")]);

  const fetchCoachViewData = (id) => {
    setCoachesListLoading(true);
    props.getCoachesViewData({
      params: { id: id },
      callback: (res, responseData) => {
        setCoachesListLoading(false);
        if (res === true) {
          setCoachesListMeta(_.get(responseData, "data.meta", {}));
          setAllCoachesList([_.get(responseData, "data.details", {})]);
        }
      },
    });
  };

  const loadCoachesList = (limit = 90, page = 1) => {
    setCoachesListLoading(true);
    props.coachesList({
      params: { limit, page, school: _.get(props, "coachProfile.school.id") },
      callback: (res, data) => {
        if (res === true) {
          setCoachesListMeta(_.get(data, "data.meta", {}));
          setAllCoachesList(_.get(data, "data.items", []));
        }
        setCoachesListLoading(false);
      },
    });
  };
  const joinPositions = (value) => {
    if (_.isArray(value) == true && _.size(value) > 0) {
      let datas = [];
      _.map(value, (v, i) => {
        datas.push(v == "WR" ? "WR/TE" : v);
      });
      return _.join(datas, ", ");
    }
  };
  const setAllCoachesList = (data) => {
    let list = [];
    if (_.size(data) > 0) {
      _.map(data, (value, index) => {
        list.push({
          key: index,
          id: _.get(value, "id"),
          first_name: _.startCase(_.get(value, "first_name")),
          last_name: _.startCase(_.get(value, "last_name")),
          email: _.get(value, "email"),
          positions: _.get(value, "positions"),
          type: _.get(value, "type"),
          // city: _.get(value, 'city'),
          // state: _.get(value, 'state'),
          // photo: _.get(value, 'photo'),
          // country: _.get(value, 'country'),
          // gender: _.get(value, 'gender'),
          // zip_code: _.get(value, 'zip_code'),
          // bio: _.get(value, 'bio'),
        });
      });

      setCoachesList(list);
    } else {
      setCoachesList([]);
    }
  };

  const hideModal = () => {
    setshowModal(false);
  };
  const onChangePage = (page, size) => {
    setPage(page);
    loadCoachesList(size, page);
  };

  const deleteCoach = (id, status) => {
    setCoachesListLoading(true);
    console.log("status", status);
    props.changeStatus({
      params: {
        id: id,
        status: status ? status : 3,
        status_glossary: "Deleted",
      },
      callback: (res, data) => {
        if (res === true) {
          console.log("Deleted", data);
          message.success("Coach deleted successfully");
          loadCoachesList();
        }
        setCoachesListLoading(false);
      },
    });
    setCoachesListLoading(false);
  };
  const render = () => {
    return (
      <div className="flex flex-col font-inter w-[98%] p-[2%]">
        <div className="flex items-center justify-between">
          <span className="text-[24px] font-bold">Manage Coaches</span>

          <div
            className="flex items-center w-[166px] rounded-[4px] bg-[#1a91ff] text-[14px] cursor-pointer justify-center h-[43px] text-[#ffffff]"
            onClick={() => openAddCoach()}
          >
            <img src={addplayer} />
            <span className="ml-[4px]"> Create a Coach</span>
          </div>
        </div>
        <div className="mt-[24px]">
          <Card
            bodyStyle={{ padding: "24px" }}
            className="w-[100%] flex flex-col"
          >
            <div className="flex items-center font-inter coachlistsearch">
              <div
                className="playerlistsearchdiv w-[50%] md:w-[394px] mr-[16px]"
                style={{ border: "1px solid #f5f5f5" }}
              >
                <input
                  type="text"
                  placeholder="Search Coach Name"
                  className="outline-0 w-[80%] p-[4px]"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <img src={searchicon} className="playerlistsearchicon" />
              </div>
              <span className="text-[12px] text-[#0F1A24]">
                You Have total of{" "}
                <span className="font-bold">{coachesList.length} Coaches</span>
              </span>
            </div>
            <div
              className="h-[36px] flex w-[85px] bg-[#F6E7E8] text-[#A40E17] items-center justify-center rounded-[4px] cursor-pointer mt-[8px]"
              onClick={() => {
                if (coachId !== "") {
                  deleteCoach(coachId);
                }
                {
                  message.error("Select a coach to be deleted");
                }
              }}
            >
              <img src={trashicon} className="w-[20px] mr-1" />
              <span className="text-[14px]">Delete</span>
            </div>
            <div className="coacheslisttablediv w-full mt-[8px]">
              {console.log("filteredCoaches", filteredCoaches)}
              <Table
                bordered
                loading={coachesListLoading}
                key={coachesList.id}
                columns={columns}
                dataSource={filteredCoaches}
                size="middle"
                pagination={false}
              />
            </div>
          </Card>
        </div>

        <CoachViewModalComponent
          isvisible={showModal}
          data={TableData}
          hideModal={hideModal}
        />
        <InviteCoachModal
          visible={inviteCoachModalVisible}
          onClose={() => setInviteCoachModalVisible(false)}
        />
        {addCoachModal && (
          <Modal
            open={addCoachModal}
            closable={false}
            onCancel={closeAddCoach}
            footer={null}
            style={{ top: 20 }}
          >
            <AddCoachModalContent
              onClose={closeAddCoach}
              loadCoachesList={loadCoachesList}
              gridironDetails={props?.gridironDetails}
            />
          </Modal>
        )}
        {console.log("coach", coach)}
        {editCoachModal && (
          <Modal
            title={<span>Edit Coach Info</span>}
            closable={true}
            onCancel={closeEditModal}
            open={editCoachModal}
            style={{ top: 20 }}
            footer={null}
          >
            <EditCoachModal
              onClose={closeEditModal}
              positions={coach?.positions}
              firstName={coach?.first_name}
              lastName={coach?.last_name}
              email={coach?.email}
              id={coach?.id}
              type={coach?.type}
              phone={coach?.phone}
              gridironDetails={props?.gridironDetails}
              updateCoach={props?.updateCoach}
              loadCoachesList={loadCoachesList}
              deleteCoach={deleteCoach}
            />
          </Modal>
        )}
        {deleteCoachModal && (
          <DeleteModals
            text="Are you sure you want to delete this Coach?."
            onOpen={deleteCoachModal}
            onCancel={() => setDeleteCoachModal(false)}
            onDelete={() => {
              deleteCoach(coachId);
              setDeleteCoachModal(false);
            }}
          />
        )}

        {passwordModalVisible && (
          <ChangePasswordModal
            visible={passwordModalVisible}
            onCancel={handleCloseModal}
            onSave={handleSavePassword}
            currentPassword={currentPassword}
            setCurrentPassword={setCurrentPassword}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            passwordResetLoading={passwordResetLoading}
            setVisible={setPasswordModalVisible}
          />
        )}
      </div>
    );
  };
  return render();
};

const mapStateToProps = (state) => {
  return {
    checkUpdate: _.get(state, "app.account.isLogin.isLoggedIn"),
    islogindata: _.get(state, "app.account.isLogin"),
    coachProfile: _.get(state, "app.account.profile.data.details"),
    coachesDetails: _.get(state, "app.coaches.view.data"),
    gridironDetails: _.get(state, "app.site.gridironDetails.data.data", {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  coachesList: (details) => dispatch(global.redux.action.coaches.list(details)),
  getCoachesViewData: (details) =>
    dispatch(global.redux.action.coaches.view(details)),
  updateCoach: (details) =>
    dispatch(global.redux.action.coaches.update(details)),
  changeStatus: (details) =>
    dispatch(global.redux.action.coaches.changeStatus(details)),
  changePassword: (details) =>
    dispatch(global.redux.action.account.changePassword(details)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoachesListComponent);
