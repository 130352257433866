import appPages from './app-pages';

export default [
    {
        key: "landing",
        element: <appPages.Landing />,
        path:"/",
        exact:true
    },
    {
        key: "login",
        element: <appPages.loginPage />,
        path:"/login",
        exact:true
    },
    {
        key: "register",
        element: <appPages.registerPage />,
        path:'/register',
        exact:true
    },
        {
        key: "register-successful",
        element: <appPages.registerSuccessful />,
        path:'/register-successful',
        exact:true
    },
        {
        key: "continue-registration",
        element: <appPages.ContinueRegistration />,
        path:'/continue-registration',
        exact:true
    },
    {
        key: "forgotPassword",
        element: <appPages.ForgotPassword />,
        path:'/forgot-password',
        exact:true
        
    },
    {
        key: "passwordVerification",
        element: <appPages.ResetPasswordComponent />,
        path:'/password-verification',
        exact:true   
    },
    {
        key: "expressCheckout",
        element: <appPages.ExpressCheckout />,
        path:'/express-checkout',
        exact:true   
    },
    {
        key: "join-wardbord",
        element: <appPages.PlayerSignup />,
        path:'/join-wardbord',
        exact:true   
    },
];
