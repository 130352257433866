import React, { useEffect, useState } from "react";
import { Card } from "antd";
import activity from "../../assets/img/activity.svg";
import teams from "../../assets/img/groupwhite.svg";
import pageStar from "../Dashboard/assets/page-star.svg";
import clipboard from "../Dashboard/assets/clipboard-check.svg";
import emojiSad from "../Dashboard/assets/emoji-sad.svg";
import emojiSatisfied from "../Dashboard/assets/emoji-satisfied.svg";
import thumbsDown from "../Dashboard/assets/thumbs-down.svg";
import { connect } from "react-redux";
import  _ from "lodash";
import running from "../../assets/img/runningwhite.svg";
import cancel from "../../assets/img/cancelwhite.svg";
import whiteHat from "../../assets/img/hatwhite.svg";
import leaf from "../../assets/img/leafwhite.svg";
import coinWhite from "../../assets/img/coinwhite.svg";
import PieChartComponent from "../../components/charts/PieChartAdmin";
import importwhite from "../../assets/img/importwhite.svg";
import PlayerAnalyticCell from "../../components/misc/PlayerAnalyticCell";


 const formatNum = (val) => {
    if (isNaN(val) || val === null) return 0;
    return Math.round(val);
 };
  
 function Dashboard(props) {
  const [analitcsList, setAnalitcsList] = useState({});
    const [planCharts, setPlanCharts] = useState([]);
  const [freePlan, setFreePlan] = useState([]);

  const fetchAnalitcsList = async () => {
    props.analytics({
      callback: (res, data) => {
        if (res === true) {
          const blob = data?.data?.items.map(({ rows }) => rows);
          
          const result = {
            ...blob[0][0],
            ...blob[1][0],
            ...blob[2][0],
            ...blob[3][0],
            ...blob[4][0],
            ...blob[5][0],
            ...blob[6][0],
            ...blob[7][0],
             
            ...blob[9][0],
            ...blob[10][0],
          }
          setPlanCharts(blob[8])
          console.log("blob for depth chart is", result, {planCharts});

          setAnalitcsList(result);
        }
      },
    })
  }

  useEffect(() => {
    fetchAnalitcsList()
  },[])

  const overviewOptions = [
    {
      title: "Teams",
      value: analitcsList?.schools || 0,
      icon: teams,
    },
    {
      title: "Active",
      value: analitcsList?.active_schools || 0,
      icon: running,
    },
    {
      title: "Canceled",
      value: analitcsList?.cancelled_schools,
      icon: cancel,
    },
    {
      title: "Coaches",
      value: analitcsList?.coaches || 0,
      icon: whiteHat,
    },
    {
      title: "Inactive Teams",
      value:  analitcsList?.inactive_schools || 0,
      icon: leaf,
    },
    {
      title: "Total Grades",
      value: analitcsList?.total_grades || 0,
      icon: coinWhite,
    },
  ];
  
  const userAnalyticsOptions = [
    {
      title: "Plays Imported",
      value: analitcsList?.plays_imported || 0,
      icon: importwhite,
    },
    {
      title: "Overall Execution",
      value: formatNum(( analitcsList?.total_assignment_executed / analitcsList?.total_grades) * 100) +'%',
      icon: clipboard,
    },
    {
      title: "Total Assignments Failed",
      value: (  analitcsList?.total_grades - analitcsList?.total_assignment_executed ),
      icon: thumbsDown,
    },
    {
      title: "Total Flags",
      value: analitcsList?.total_flags,
      icon: pageStar,
    },
    {
      title: "Overall Effort",
      value: formatNum(( analitcsList?.total_great_effort / analitcsList?.total_grades) * 100) +'%',
      icon: emojiSatisfied,
    },
    {
      title: "Total Bad Effort Plays",
      value:  (  analitcsList?.total_grades - analitcsList?.total_great_effort ),
      icon: emojiSad,
    },
  ];
 
  return (
    <div className="flex flex-col font-inter w-[98%] p-[2%]">
      <h2 className="mt-[4px] xl:mt-[0.5vw] text-[23px] xl:text-[1.7vw] font-bold">
        Dashboard
      </h2>
      <Card bodyStyle={{ padding: "24px" }}>
        <div>
          <div className="flex items-center gap-2">
            <img src={activity} />
            <h6 className="text-[#0F1A24] text-sm font-bold">
              Wardbord Subscription Overview
            </h6>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div className="col-span-1 grid grid-cols-3 gap-4">
              {overviewOptions.map(({ title, value, icon, bg }) => (
                <div className="col-span-1 flex flex-col justify-center items-center border-2 border-[#EAEDF0] rounded-lg">
                  <img
                    src={icon}
                    className={`p-2 rounded-full bg-[#2E4D6B] mt-3`}
                  />
                  <span className="mt-4 text-2xl font-bold text-[#2E4D6B]">
                    {value}
                  </span>
                  <span className="mb-8 text-[12px] font-normal text-[#20364B]">
                    {title}
                  </span>
                </div>
              ))}
            </div>
            <div className="col-span-1 border-2 border-[#EAEDF0] rounded-lg h-full p-6">
              <div className="flex gap-4">
                {planCharts?.map(({plan_name, count})=>{
  return (<div className="flex items-center gap-1 ">
                  <div className="w-[12px] h-[12px] bg-[#00B21E] rounded-full"></div>
                  <span className="text-[#2E4D6B] text-[12px] font-normal">
                    {plan_name} ({count})
                  </span>
                </div>)
                    } )}
                
                
              </div>
              <div>
                <PieChartComponent
                  title={""}
                  data={
                    planCharts?.map(({plan_name, count})=>{
                      return {
                        name: plan_name,
                        value: count,
                      }
                    } ) }
                  width={300}
                />

                
              </div>
            </div>
          </div>
        </div>
      </Card>
      <div className="mt-4">
        <Card bodyStyle={{ padding: "24px" }}>
          <div>
            <div className="flex items-center gap-2">
              <img src={activity} />
              <h6 className="text-[#0F1A24] text-sm font-bold">
                User Base Analytics
              </h6>
            </div>
            <div className="grid grid-cols-6 gap-4 mt-4">
              {userAnalyticsOptions.map(({ title, value, icon }) => {
                return (
                  <div className="col-span-1 flex flex-col justify-center items-center border-2 border-[#EAEDF0] rounded-lg">
                    <img
                      src={icon}
                      className={`p-2 rounded-full bg-[#2E4D6B] mt-3`}
                    />
                    <span className="mt-4 text-2xl font-bold text-[#2E4D6B]">
                      {value}
                    </span>
                    <span className="mb-8 mt-2 text-[12px] text-center font-normal text-[#20364B]">
                      {title}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="w-[100%] flex flex-wrap justify-between bg-white mt-4">
              {/* exec */}
              <PlayerAnalyticCell
                Title={"Execution Report"}
                total_games={0}
                TotalAssignmentsEvaluated={analitcsList?.total_assignment_executed}
                Avg={"Execution"}
                winValue={formatNum((analitcsList?.total_assignment_executed_in_won_games /
                  analitcsList?.total_grades_in_won_games)*
                  100)}
                
                 lossValue={formatNum((analitcsList?.total_assignment_executed_in_lost_games /
                  analitcsList?.total_grades_in_lost_games)*
                   100)}
                
                  totalPlays={analitcsList?.plays_imported}
                
                calcParam={"Assignments Failed"}
                calcParamValue={(analitcsList?.total_grades - analitcsList?.total_assignment_executed)}
                differential={"--"}
                formatNum={formatNum}
              />
              <PlayerAnalyticCell
                Title={"Effort Report"}
                TotalAssignmentsEvaluated={analitcsList?.total_great_effort}
                Avg={"Effort"}
                total_games={0}
                formatNum={formatNum}

                  lossValue={formatNum((analitcsList?.total_great_effort_in_lost_games /
                  analitcsList?.total_grades_in_lost_games)*
                    100)}
                 winValue={formatNum((analitcsList?.total_great_effort_in_won_games /
                  analitcsList?.total_grades_in_won_games)*
                   100)} 
                 
                calcParam={"Bad Effort Plays"}
                calcParamValue={( analitcsList?.total_grades - analitcsList?.total_great_effort)}
              />
            </div>
          </div>
        </Card>
      </div>
        
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    coachProfile: _.get(state, "app.account.profile.data.details"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  analytics: (details) =>
    dispatch(global.redux.action.super_admin.analytics(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
