import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Select, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function InvitePlayerModalContent(props) {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [invitedPlayers, setInvitedPlayers] = useState([]);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [form] = Form.useForm();

  const capitalizeString = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleInvitePlayer = (values) => {
    setInviteLoading(true);
    let details = {
      school: props.coachProfile.school.id,
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      positions: values.positions.toString(),
      redirect_url: "wardbord://path/invitePlayer",
      phone: values.phone || `202-272-0167`,
    };

    setButtonLoading(true);
    props.athleteCreate({
      body: details,
      callback: (res, responseData) => {
        setButtonLoading(false);
        if (res === true) {
          console.log("responseData--", responseData);
          form.resetFields();
          setInvitedPlayers([...invitedPlayers, details]);
          notification.success({
            message: "Success",
            description:
              "Player has been added! Please have the player download the mobile app from the app store! The login details are their email (all lowercase) and their password is: wardbord23(all lowercase)",
          });
        }
        setInviteLoading(false);
        props?.onCancel();
      },
    });
  };

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleInvitePlayer}
        className="flex flex-col min-h-[400px]"
      >
        <Form.Item
          label={
            <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
              First name
            </span>
          }
          name="first_name"
          rules={[{ required: true, message: "Please input the first name!" }]}
        >
          <Input className="w-full border-[1px] p-[8px] font-inter text-[14px] outline-none" />
        </Form.Item>
        <Form.Item
          label={
            <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
              Last name
            </span>
          }
          name="last_name"
          rules={[{ required: true, message: "Please input the last name!" }]}
        >
          <Input className="w-full border-[1px] p-[8px] font-inter text-[14px] outline-none" />
        </Form.Item>
        <Form.Item
          label={
            <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
              Phone Number
            </span>
          }
          name="phone"
          rules={[
            { required: true, message: "Please input the phone number!" },
          ]}
        >
          <Input className="w-full border-[1px] p-[8px] font-inter text-[14px] outline-none" />
        </Form.Item>
        <Form.Item
          label={
            <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
              Email Address
            </span>
          }
          name="email"
          rules={[
            { required: true, message: "Please input the email address!" },
            { type: "email", message: "Please enter a valid email!" },
          ]}
        >
          <Input className="w-full border-[1px] p-[8px] font-inter text-[14px] outline-none" />
        </Form.Item>
        <Form.Item
          label={
            <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
              Position
            </span>
          }
          name="positions"
          rules={[
            { required: true, message: "Please select the position(s)!" },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="Select Position(s)"
            className="w-full rounded-[4px] text-[#0F1A24] mt-[5px]"
            optionLabelProp="label"
            size="large"
            options={[
              {
                label: (
                  <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
                    Defensive unit
                  </span>
                ),
                options:
                  props.gridironDetails?.player_positions?.defense[0]?.variables?.map(
                    ({ code, name }) => ({
                      label: (
                        <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                          <span className="font-bold">{code}</span>{" "}
                          {`(${capitalizeString(name)})`}
                        </span>
                      ),
                      value: code,
                    })
                  ) || [],
              },
              {
                label: (
                  <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
                    Offensive unit
                  </span>
                ),
                options:
                  props.gridironDetails?.player_positions?.offense[0]?.variables?.map(
                    ({ code, name }) => ({
                      label: (
                        <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                          <span className="font-bold">{code}</span>{" "}
                          {`(${capitalizeString(name)})`}
                        </span>
                      ),
                      value: code,
                    })
                  ) || [],
              },
              {
                label: (
                  <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
                    Special Teams
                  </span>
                ),
                options:
                  props.gridironDetails?.player_positions?.special_teams[0]?.variables?.map(
                    ({ code, name }) => ({
                      label: (
                        <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                          <span className="font-bold">{code}</span>{" "}
                          {`(${capitalizeString(name)})`}
                        </span>
                      ),
                      value: code,
                    })
                  ) || [],
              },
            ]}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={inviteLoading}
            className="flex items-center justify-center w-[50%] h-[43px] rounded-[4px]"
          >
            + Add Player
          </Button>
        </Form.Item>
        {invitedPlayers.map(({ email }) => (
          <div
            key={email}
            className="mt-[10px] w-full h-[36px] flex items-center justify-center bg-[#E8F4FF] rounded-[4px]"
          >
            <div className="flex text-[12px] items-center font-inter w-[97%] justify-between">
              <span className="text-[black]">{email}</span>
            </div>
          </div>
        ))}
      </Form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    coachProfile: state.app.account.profile.data.details,
  };
};

const mapDispatchToProps = (dispatch) => ({
  athleteCreate: (details) =>
    dispatch(global.redux.action.athletes.create(details)),
  getGridironDetails: (details) =>
    dispatch(global.redux.action.grades.gridironDetails(details)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitePlayerModalContent);
